import dayjs from 'dayjs'

export class DateFormatter {
  static toValidDBDate (selectedDate) {
    if (!selectedDate) return null

    const validFormat = /^\d{2}\/\d{2}\/\d{4}$/.test(selectedDate)
    if (validFormat) return selectedDate

    const [day, month, year] = selectedDate.split('/')
    const monthMap = {
      Mar: 3,
      Ago: 8,
      Ene: 1,
      Feb: 2,
      Abr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dic: 12,
    }

    const isoMonth = monthMap[month]
    if (!isoMonth) {
      console.error('Invalid month')
      return null
    }

    const isoDate = `${year}-${isoMonth}-${day}`
    const date = dayjs(isoDate)

    if (!date.isValid()) {
      console.error('Invalid selectedDate format')
      return null
    }

    return date.format('DD/MM/YYYY')
  }
}
