// @ts-nocheck
// Styles
import './GAutocomplete.sass'
import VAutocomplete from '../../../components/VAutocomplete/VAutocomplete'

/* @vue/component */
export default VAutocomplete.extend({
  name: 'g-autocomplete',

  computed: {
    inputFilled (): boolean {
      const { lazyValue } = this

      return lazyValue?.length || this.initialValue
    },
    classes (): object {
      const { inputFilled } = this
      return {
        ...VAutocomplete.options.computed.classes.call(this),
        'g-autocomplete': true,
        'g-autocomplete--has-content': inputFilled,
      }
    },
  },
})
