
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { ContentBase } from '@/components/forms/inspection/steppers/content/ContentBase'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { sortArray } from '@/utils/array/manipulation'
  import { isArray } from 'class-validator'
  import { Debounce } from '@/utils/decorators'
  import { Component as CategoryComponent } from '@/entities/vehicle'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import GCostField from '@/components/core/input/GCostField.vue'

@Component({
  components: { GCostField, GRadioButton, GFiles, GFormSlot },
  methods: {
    sortArray,
  },
})
  export default class BasicEquipmentContent extends ContentBase {
  @Prop({ type: Object }) keyComponent: CategoryComponent
  items = []
  fieldsKeys = ['status']
  loadingSend = false

  formData = {
    status: {
      answer: null,
      provisional: false,
    },
    copy: {
      answer: null,
    },
    cost: undefined,
    photo: null,
  }

  errorIdentifierMessage = {
    status: {
      status: '',
      provisional: ''
      ,
    },
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  async send () {
    const { formData: data } = this
    if (this.validateFields(data)) {
      return
    }
    this.loadingSend = true
    const { idProcess, idEmployee, inspection, keyComponent: component, photoProperties, idProcessInspection } = this
    let resp

    const statusData = data?.status?.provisional ? [data.status.provisional] : data.status.answer
    const copy = data.copy.answer

    const inspectionUpdated = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: inspection.id } },
      force: true,
    })

    const matchingInspectedComponent = inspectionUpdated.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      resp = matchingInspectedComponent
      await this.edit(resp, data.cost, { statusData, copy })
    } else {
      const fields = {
        id_inspection: inspection.id,
        id_inspection_component: component.inspectionComponent.id,
        id_process: idProcessInspection,
        cost: data?.cost,
        comment: undefined,
        inspection_qualifications: {
          data: [
            ...statusData.map(status => {
              return {
                id_employee: idEmployee,
                id_inspection_parameter: component?.findQuestion(1)?.id,
                id_inspection_assessment: status,
              }
            }),
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(2)?.id,
              id_inspection_assessment: copy,
            },
          ].filter(_ => _.id_inspection_assessment),
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })

      await this.createInspection(inspection)
    }
    await this.addFiles(data.photo, photoProperties, idProcess, resp)
    const cost = typeof data.cost === 'string' ? parseInt(data.cost) : data.cost
    this.$emit('input', cost)
    this.$emit('next')
    this.loadingSend = false
  }

  @Watch('formData', { immediate: true, deep: true })
  @Debounce(100)
  onFormDataChange (val) {
    this.initiateErrorMessage()
    const { keyComponent, fieldsKeys, validation } = this
    if (validation) {
      this.validation = false
      return
    }
    this.cost = this.isAnyFieldValid(fieldsKeys, val, keyComponent)

    if (!this.cost && val.cost && !this.isOnlyOnceKey) {
      this.formData.cost = 0
    }
  }

  initiateErrorMessage () {
    this.errorIdentifierMessage.status.status = ''
    this.errorIdentifierMessage.status.provisional = ''
  }

  setErrorMessage (field) {
    if (this.formData[field]?.provisional === null || this.formData[field]?.provisional === undefined) {
      this.errorIdentifierMessage[field].provisional = 'Este campo es requerido'
    } else if (this.formData[field]?.provisional !== null || this.formData[field]?.provisional !== undefined) {
      this.errorIdentifierMessage[field].provisional = ''
    }

    this.errorIdentifierMessage[field].status = 'Este campo es requerido'
  }

  validateFields (data) {
    const fields = ['status']
    let isValid = false

    const handleValidation = field => {
      const isArr = isArray(data[field]?.answer)
      const isNum = !isNaN(data[field]?.answer)

      if ((isNum && !data[field]?.answer) || (isArr && !data[field]?.answer.length)) {
        this.setErrorMessage(field)
        return true
      }
      this.errorIdentifierMessage[field].status = ''
      return false
    }

    for (const field of fields) {
      const isProvisional = 'provisional' in data[field]
      const validNotProvisional = isProvisional === false && handleValidation(field)
      const validProvisional = (isProvisional === true && !data[field]?.provisional) && handleValidation(field)

      if (data[field]?.provisional) {
        this.validation = true

        if (this.formData?.[field]?.answer) {
          this.formData[field].answer = []
        }
      }

      if (validNotProvisional || validProvisional) {
        isValid = true
        break
      } else {
        this.errorIdentifierMessage[field].status = ''
      }
    }

    return isValid
  }

  @Watch('keyComponent', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    const { idProcess, inspection } = this

    const matchingInspectedComponent = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === val?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      this.formData.photo = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: matchingInspectedComponent.id } },
            { parameter: { process: { id: { _eq: idProcess } } } },
            { parameter: { file_type: { name: { _eq: 'photo' } } } },
          ],
        },
        force: true,
      })

      this.processFormData('status', 1, matchingInspectedComponent)
      this.formData.cost = matchingInspectedComponent.cost
      this.formData.copy.answer = matchingInspectedComponent?.findInspectionQualificationByOrder(2)?.[0]?.assessment?.id
      if (matchingInspectedComponent.cost) {
        this.cost = true
      }
    }
    const cost = typeof this.formData.cost === 'string' ? parseInt(this.formData.cost) : this.formData.cost
    this.$emit('input', cost)
    if ((this.formData.status.provisional === false && this.formData.status.answer) || (this.formData.status.provisional)) {
      this.$emit('next')
    }
  }

  processFormData (field, order, component) {
    const param = component.findInspectionParameterByOrder(order)
    const qual = component.findInspectionQualificationByOrder(order)

    if (!param?.assessment?.score) {
      this.formData[field].provisional = param?.assessment?.id
      this.formData[field].answer = null
    } else {
      this.formData[field].provisional = false
      this.formData[field].answer = qual.map(item => item.assessment.id)
    }
  }

  async edit (component, cost, data) {
    const { statusData, copy } = data
    const { idEmployee } = this
    await this.editMultipleComponent(component, statusData, idEmployee, 1)

    const parameter = component.findInspectionParameterByOrder(2)
    if (parameter.assessment.id !== copy) {
      const fields = {
        id: parameter.id,
        id_inspection_assessment: copy,
      }
      await this.pushData({ model: 'InspectionQualification', fields })
    }

    await this.pushData({
      model: 'InspectionInspectedComponent',
      fields: { id: component.id, cost },
    })
  }

  get displayPhoto () {
    const { keyComponent, formData: { copy }, getQuestionOptions } = this
    if (!copy?.answer) return false

    return !getQuestionOptions(keyComponent, 2).find(item => item.id === copy.answer)?.score
  }

  get isOnlyOnceKey () {
    const { keyComponent, formData: { copy }, getQuestionOptions } = this
    if (!copy?.answer) return false

    return getQuestionOptions(keyComponent, 2).find(item => item.id === copy.answer)?.score
  }
  }
