
  import { Component, Prop } from 'vue-property-decorator'
  import Cell from './cell'

@Component
  export default class RatingCell extends Cell {
  @Prop({ type: [Object, Array, Number, String] }) readonly value!: any;
  @Prop({ type: Boolean, default: true }) readonly !: boolean
  @Prop({ type: Boolean, default: false }) small !: boolean
  @Prop({ type: Boolean, default: false }) inspection !: boolean
  @Prop({ type: Boolean, default: false }) blueStar !: boolean
  @Prop({ type: String, default: 'center' }) readonly justify!: string;
  @Prop({ type: Number, default: 5 }) readonly maxLength: number;

  get current () {
    const { item } = this

    if (!item) return
    const { supervisor, inspector } = item

    return supervisor || inspector || item
  }

  get supervisor () {
    const { item } = this

    return item?.supervisor
  }

  get inspector () {
    const { item } = this

    return item?.inspector
  }

  get qualifier () {
    const { supervisor } = this

    if (!supervisor) return

    return supervisor?.qualifier?.name
  }

  get qualiferInspector () {
    const { inspector } = this

    if (!inspector) return

    return inspector?.qualifier?.name
  }

  get qualificationInspector () {
    const { inspector } = this

    if (!inspector) return

    return inspector?.qualification
  }

  get roleInspector () {
    const { inspector } = this

    if (!inspector) return

    return inspector?.role
  }

  get role () {
    const { supervisor } = this
    if (!supervisor) return

    return supervisor?.role
  }

  get qualification () {
    const { current, value } = this

    if (!current && !value) return 0

    if (typeof current === 'number') {
      return current
    }
    const { supervisor } = this
    return supervisor?.qualification || value
  }

  get fullStar () {
    const { role, qualification, blueStar } = this

    if (blueStar) {
      return '$g_star_inspector'
    }

    if (!role && !qualification) return '$g_star'
    return role === 'Inspector' ? '$g_star_inspector' : '$g_star'
  }

  get halfStar () {
    const { role, blueStar } = this

    if (blueStar) {
      return '$g_star_half_inspector'
    }

    if (!role) return '$g_star_half'
    return role === 'Inspector' ? '$g_star_half_inspector' : '$g_star_half'
  }

  get size () {
    const { small, inspection } = this

    if (inspection) {
      return 20
    }

    return !small ? 40 : 35
  }
  }
