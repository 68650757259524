
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { ContentBase } from '@/components/forms/inspection/steppers/content/ContentBase'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { sortArray } from '@/utils/array/manipulation'
  import { isArray } from 'class-validator'
  import { Debounce } from '@/utils/decorators'
  import { Component as CategoryComponent } from '@/entities/vehicle'
  import GCostField from '@/components/core/input/GCostField.vue'

@Component({
  components: { GCostField, GFiles, GFormSlot },
  methods: {
    sortArray,
  },
})
  export default class CarExhaustContent extends ContentBase {
  @Prop({ type: Object }) exhaust: CategoryComponent
  items = []
  fieldsKeys = ['noise', 'status', 'emissions']
  loadingSend = false

  formData = {
    noise: {
      answer: null,
      provisional: null,
    },
    status: {
      answer: null,
      provisional: null,
    },
    emissions: {
      answer: null,
      provisional: null,
    },
    cost: undefined,
    photo: null,
  }

  errorIdentifierMessage = {
    noise: {
      status: '',
    },
    status: {
      status: '',
      provisional: '',
    },
    emissions: {
      status: '',
      provisional: '',
    },
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  async send () {
    const { formData: data } = this
    if (this.validateFields(data)) {
      return
    }
    this.loadingSend = true
    const { idProcess, idEmployee, inspection, exhaust: component, photoProperties, cost, idProcessInspection } = this
    let resp

    const statusData = data?.status?.provisional ? [data.status.provisional] : data.status.answer
    const emissionsData = data?.emissions?.provisional ? [data.emissions.provisional] : data.emissions.answer

    const matchingInspectedComponent = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      resp = matchingInspectedComponent
      await this.edit(resp, data.cost, { noiseData: data.noise.answer, statusData, emissionsData })
    } else {
      const fields = {
        id_inspection: inspection.id,
        id_inspection_component: component.inspectionComponent.id,
        id_process: idProcessInspection,
        cost: data?.cost,
        comment: undefined,
        inspection_qualifications: {
          data: [
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(1)?.id,
              id_inspection_assessment: data.noise.answer,
            },
            ...statusData.map(status => {
              return {
                id_employee: idEmployee,
                id_inspection_parameter: component?.findQuestion(2)?.id,
                id_inspection_assessment: status,
              }
            }),
            ...emissionsData.map(emission => {
              return {
                id_employee: idEmployee,
                id_inspection_parameter: component?.findQuestion(3)?.id,
                id_inspection_assessment: emission,
              }
            }),
          ].filter(_ => _.id_inspection_assessment),
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }

    if (!cost && data?.photo?.length) {
      await this.deleteFiles(data.photo)
    } else {
      await this.addFiles(data.photo, photoProperties, idProcess, resp)
    }
    this.$emit('input', { cost: this.formData.cost })
    this.$emit('next')
    this.$emit('update:inspection')
    this.loadingSend = false
  }

  @Watch('formData', { immediate: true, deep: true })
  @Debounce(100)
  onFormDataChange (val) {
    this.initiateErrorMessage()
    const { exhaust, fieldsKeys, validation } = this
    if (validation) {
      this.validation = false
      return
    }
    this.cost = this.isAnyFieldValid(fieldsKeys, val, exhaust)

    if (!this.cost && val.cost) {
      this.formData.cost = 0
    }
  }

  initiateErrorMessage () {
    this.errorIdentifierMessage.status.status = ''
    this.errorIdentifierMessage.status.provisional = ''
    this.errorIdentifierMessage.noise.status = ''
    this.errorIdentifierMessage.emissions.status = ''
    this.errorIdentifierMessage.emissions.provisional = ''
  }

  setErrorMessage (field) {
    if (this.formData[field]?.provisional === null || this.formData[field]?.provisional === undefined) {
      this.errorIdentifierMessage[field].provisional = 'Este campo es requerido'
    } else if (this.formData[field]?.provisional !== null || this.formData[field]?.provisional !== undefined) {
      this.errorIdentifierMessage[field].provisional = ''
    }

    this.errorIdentifierMessage[field].status = 'Este campo es requerido'
  }

  validateFields (data) {
    const fields = ['noise', 'status', 'emissions']
    let isValid = false

    const handleValidation = field => {
      const isArr = isArray(data[field]?.answer)
      const isNum = !isNaN(data[field]?.answer)

      if ((isNum && !data[field]?.answer) || (isArr && !data[field]?.answer.length)) {
        this.setErrorMessage(field)
        return true
      }
      this.errorIdentifierMessage[field].status = ''
      return false
    }

    for (const field of fields) {
      const isProvisional = 'provisional' in data[field]
      const validNotProvisional = isProvisional === false && handleValidation(field)
      const validProvisional = (isProvisional === true && !data[field]?.provisional) && handleValidation(field)

      if (data[field]?.provisional) {
        this.validation = true

        if (this.formData?.[field]?.answer) {
          this.formData[field].answer = []
        }
      }

      if (validNotProvisional || validProvisional) {
        isValid = true
        break
      } else {
        this.errorIdentifierMessage[field].status = ''
      }
    }

    return isValid
  }

  @Watch('exhaust', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    const { idProcess, inspection, displayQualification } = this

    const matchingInspectedComponent = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === val?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      this.formData.photo = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: matchingInspectedComponent.id } },
            { parameter: { process: { id: { _eq: idProcess } } } },
            { parameter: { file_type: { name: { _eq: 'photo' } } } },
          ],
        },
        force: true,
      })

      this.formData.noise.answer = matchingInspectedComponent?.findInspectionQualificationByOrder(1)?.[0]?.assessment?.id
      this.processFormData('status', 2, matchingInspectedComponent)
      this.processFormData('emissions', 3, matchingInspectedComponent)

      this.formData.cost = matchingInspectedComponent.cost

      if (matchingInspectedComponent.cost) {
        this.cost = true
      }
    }
    this.$emit('input', { cost: this.formData.cost })
    if (((this.formData.status.provisional === false && this.formData.status.answer) || (this.formData.status.provisional)) && !displayQualification) {
      this.$emit('next')
    }
  }

  processFormData (field, order, component) {
    const param = component.findInspectionParameterByOrder(order)
    const qual = component.findInspectionQualificationByOrder(order)

    if (!param?.assessment?.score) {
      this.formData[field].provisional = param?.assessment?.id
      this.formData[field].answer = null
    } else {
      this.formData[field].provisional = false
      this.formData[field].answer = qual.map(item => item.assessment.id)
    }
  }

  async edit (component, cost, data) {
    const { noiseData, statusData, emissionsData } = data
    const { idEmployee } = this

    await this.editMultipleComponent(component, statusData, idEmployee, 2)
    await this.editMultipleComponent(component, emissionsData, idEmployee, 3)

    const parameter = component.findInspectionParameterByOrder(1)
    if (parameter.assessment.id !== noiseData) {
      const fields = {
        id: parameter.id,
        id_inspection_assessment: noiseData,
      }
      await this.pushData({ model: 'InspectionQualification', fields })
    }

    await this.pushData({
      model: 'InspectionInspectedComponent',
      fields: { id: component.id, cost },
    })
  }
  }
