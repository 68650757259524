
  import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator'

@Component
  export default class CustomRadioGroup extends Vue {
  @Prop([String, Number, Boolean]) readonly yesId!: string | number | boolean | null;
  @Prop([String, Number, Boolean]) readonly noId!: string | number | boolean | null;
  @Prop({ type: String, default: 'end' }) readonly align!: string;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: () => [] }) readonly errorMessages!: string[] | string;

  @Model('input', { default: null }) readonly value!: string | number | boolean | null;

  @Emit()
  onValueChange (newValue: string | number | boolean | null) {
    return newValue
  }

  selectOption (optionId: string | number | boolean | null) {
    if (optionId !== this.value) {
      this.$emit('input', optionId)
    }
  }

  iconColor (optionId: string | number | boolean | null): string {
    if (optionId === this.yesId) {
      return 'rgba(0, 128, 0, 0.3)'
    }
    if (optionId === this.noId) {
      return 'rgba(255, 0, 0, 0.3)'
    }
    return 'rgba(0, 0, 0, 0.3)'
  }

  isSelected (optionId: string | number | boolean | null): boolean {
    return this.value === optionId
  }
  }
