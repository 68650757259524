import { Component, Prop } from 'vue-property-decorator'
import { InputRule, RULES } from '@/components/forms'
import { FilesProcess } from '@/components/forms/view/FilesProcess'

@Component
export class Field extends FilesProcess {
  @Prop({ type: [Object, Array, Number, String, Boolean] }) readonly value!: any
  @Prop({ type: String }) readonly name!: string
  @Prop({ type: String }) readonly errorMessages!: string
  @Prop({ type: String }) readonly label!: string
  @Prop({ type: Boolean }) readonly required!: boolean
  @Prop({ type: Boolean }) readonly multiple!: boolean
  @Prop({ type: Boolean }) readonly readonly!: boolean
  @Prop({ type: Boolean }) readonly chip!: boolean
  @Prop({ type: Boolean }) readonly disabled!: boolean;
  @Prop({ type: Array, default: () => [] }) readonly rules: Array<InputRule>;

  fieldRequired = RULES.isRequired;

  get () {
    return this.value
  }

  set (value) {
    this.$emit('input', value)
    this.$emit('clear')
  }
}
