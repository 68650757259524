
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { FileProcess } from '@/entities/files'

@Component
  export default class GImg extends Vue {
  @Prop({ type: [String, Number], default: 185 }) readonly size!: string | number;
  @Prop({ type: FileProcess, default: null }) readonly file!: FileProcess;
  hover = false

  openImage (uri: string) {
    window.open(uri, '_blank')
  }

  get small () {
    const { file } = this

    return file.file?.metadata?.s?.uri || file?.file?.uri
  }

  get medium () {
    const { file } = this

    return file.file?.metadata?.m?.uri
  }

  get large () {
    const { file } = this

    return file.file?.metadata?.l?.uri
  }

  get srcLarge () {
    const { medium, large, file } = this

    return medium || large || file?.file?.uri
  }

  get isPdf () {
    const { file } = this

    return file?.parameter?.fileType?.isPdf
  }
  }
