import { Component, Prop } from 'vue-property-decorator'
import { GForm } from '@/components/forms/GForm'
import { Inspection, NegotiationResponseType } from '@/entities/purchase'
import { replaceText } from '@/utils/general'
import dayjs from 'dayjs'
import { DateFormatter } from '@/utils/date/DateFormatter'
import { ComponentCategory, Generation } from '@/entities/vehicle'
import { mapActions } from 'vuex'
import { sortArray } from '@/utils/array/manipulation'
import { isArray } from 'class-validator'

@Component({
  methods: {
    replaceText,
    ...mapActions('resources/form', ['deleteFile']),
  },
})
export class InspectionBase extends GForm {
  @Prop({ type: Number, default: null }) idProcessInspection: number
  @Prop({ type: Boolean, default: false }) disabled: boolean
  @Prop({ type: Boolean, default: false }) reset: boolean
  @Prop({ type: Boolean, default: false }) supervisor: boolean
  @Prop({ type: Boolean, default: false }) categoryError: boolean
  @Prop({ type: Boolean, default: false }) overCategory: boolean
  @Prop({ type: Boolean, default: false }) selfPanel: boolean
  @Prop({ type: Boolean, default: false }) summary: boolean
  @Prop({ type: Boolean, default: false }) inspectionFinished: boolean
  @Prop({ type: Boolean, default: false }) sendQualification: boolean
  @Prop({ type: Boolean, default: false }) displayQualification: boolean
  @Prop({ type: Boolean, default: false }) autoClose: boolean
  @Prop({ type: Object, default: () => ({}) }) inspection: Inspection
  @Prop({ type: Object, default: () => ({}) }) generation: Generation
  @Prop({ type: Number, default: null }) idProcess: number
  @Prop({ type: String, default: null }) categoryName: string
  @Prop({ type: Object }) category!: ComponentCategory
  @Prop({ type: Object, default: () => ({}) }) photoProperties: Record<string, any>
  @Prop({ type: Number, default: null }) maxMileageDifference: number
  @Prop({ type: Number, default: null }) minPercentageDifference: number
  @Prop({ type: Object, default: () => null }) categoryQualificationConfig: Record<string, any>

  inspectionStatus = {
    active: null,
    close: null,
    finished: null,
    validated: null,
    supervision: null,
    successful: null,
  }

  autoStatus = {
    deprecated: null,
  }

  dealStatus = {
    lost: null,
  }

  dealClosingReason = {
    not_qualify: null,
  }

  inspectionClosingReason = {
    notQualify: null,
    closed: null,
  }

  isUploadingFile = false

  async sendOtherDocuments (component, data, fileProperties, id, key) {
    const { idProcess, idEmployee, inspection, supervisor, idProcessInspection } = this

    let resp

    const inspectionUpdated = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: inspection.id } },
      force: true,
    })

    const matchingInspectedComponent = inspectionUpdated.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      resp = matchingInspectedComponent

      await this.pushData({
        model: 'InspectionQualification',
        fields: {
          id: matchingInspectedComponent.findInspectionParameterByOrder(1).id,
          value: key !== 'technicalReview' ? key === 'soap' ? data.selection.alias : data.selection.name : data.selection.alias,
          validated: supervisor,
        },
      })

      if (matchingInspectedComponent.findInspectionParameterByOrder(2).value !== data.date || supervisor) {
        await this.pushData({
          model: 'InspectionQualification',
          fields: {
            id: matchingInspectedComponent.findInspectionParameterByOrder(2).id,
            value: DateFormatter.toValidDBDate(data.date),
            validated: supervisor,
          },
        })
      }
      const fields = {
        id: matchingInspectedComponent.id,
        cost: data?.cost,
        supervisor_cost: data?.cost,
        id_process: idProcessInspection,
      }

      if (supervisor) {
        delete fields.cost
      } else {
        delete fields.supervisor_cost
      }

      await this.pushData({
        model: 'InspectionInspectedComponent',
        fields,
      })
    } else {
      const fields = {
        id_inspection: id,
        id_process: idProcessInspection,
        id_inspection_component: component.inspectionComponent.id,
        cost: data?.cost,
        comment: undefined,
        inspection_qualifications: {
          data: [{
            id_employee: idEmployee,
            id_inspection_parameter: component.findQuestion(1)?.id,
            value: key !== 'technicalReview' ? key === 'soap' ? data.selection.alias : data.selection.name : data.selection.alias,
          }, {
            id_employee: idEmployee,
            id_inspection_parameter: component.findQuestion(2)?.id,
            value: DateFormatter.toValidDBDate(data.date),
          }],
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }

    await this.addFiles(data.photo, fileProperties, idProcess, resp)
    this.$emit('update:inspection')
  }

  async sendDocumentCirculationPermit (component, data, fileProperties, id) {
    const { idProcess, idEmployee, inspection, supervisor, idProcessInspection } = this

    let resp
    const inspectionUpdated = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: inspection.id } },
      force: true,
    })

    const matchingInspectedComponent = inspectionUpdated.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      resp = matchingInspectedComponent

      if (matchingInspectedComponent.findInspectionParameterByOrder(1).value !== data.selection.name || supervisor) {
        await this.pushData({
          model: 'InspectionQualification',
          fields: {
            id: matchingInspectedComponent.findInspectionParameterByOrder(1).id,
            value: data.selection.name,
            validated: supervisor,
          },
        })
      }
      if (matchingInspectedComponent.findInspectionParameterByOrder(2).value !== data.date || supervisor) {
        await this.pushData({
          model: 'InspectionQualification',
          fields: {
            id: matchingInspectedComponent.findInspectionParameterByOrder(2).id,
            value: DateFormatter.toValidDBDate(data.date),
            validated: supervisor,
          },
        })
      }

      if (matchingInspectedComponent.findInspectionParameterByOrder(3).value !== data.amount || supervisor) {
        await this.pushData({
          model: 'InspectionQualification',
          fields: {
            id: matchingInspectedComponent.findInspectionParameterByOrder(3).id,
            value: data.amount,
            validated: supervisor,
          },
        })
      }

      const fields = {
        id: matchingInspectedComponent.id,
        cost: data?.cost,
        supervisor_cost: data?.cost,
        id_process: idProcessInspection,
      }

      if (supervisor) {
        delete fields.cost
      } else {
        delete fields.supervisor_cost
      }

      await this.pushData({
        model: 'InspectionInspectedComponent',
        fields,
      })
    } else {
      const fields = {
        id_inspection: id,
        id_process: idProcessInspection,
        id_inspection_component: component.inspectionComponent.id,
        cost: data?.cost,
        comment: undefined,
        inspection_qualifications: {
          data: [{
            id_employee: idEmployee,
            id_inspection_parameter: component.findQuestion(1)?.id,
            value: data.selection.name,
          }, {
            id_employee: idEmployee,
            id_inspection_parameter: component.findQuestion(2)?.id,
            value: DateFormatter.toValidDBDate(data.date),
          },
          {
            id_employee: idEmployee,
            id_inspection_parameter: component.findQuestion(3)?.id,
            value: data.amount,
          }],
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }

    await this.addFiles(data.photo, fileProperties, idProcess, resp)
    this.$emit('update:inspection')
  }

  async sendComponentQualification (component, data, fileProperties, id, mileageAttribute = null, supervisor = false) {
    const { idProcess, idEmployee, inspection, idProcessInspection } = this

    if (!inspection?.date) {
      await this.createInspection(inspection)
    }

    let resp

    const inspectionUpdated = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: inspection.id } },
      force: true,
    })

    const matchingInspectedComponent = inspectionUpdated.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      resp = matchingInspectedComponent

      const fields = {
        id: matchingInspectedComponent.inspectionQualification.id,
        id_inspection_assessment: !component?.inspectionParameter?.assessment?.id ? undefined : data.answer,
        id_inspection_parameter: component?.inspectionParameter?.id,
        value: !component?.inspectionParameter?.assessment?.id ? data.answer : undefined,
        validated: supervisor,
      }

      await this.pushData({ model: 'InspectionQualification', fields })

      const fieldsToUpdate = {
        id: matchingInspectedComponent.id,
        cost: data?.cost,
        supervisor_cost: data?.supervisorCost,
        id_process: idProcessInspection,
      }

      if (supervisor) {
        delete fieldsToUpdate.cost
      } else {
        delete fieldsToUpdate.supervisor_cost
      }

      await this.pushData({
        model: 'InspectionInspectedComponent',
        fields: fieldsToUpdate,
      })
    } else {
      const fields = {
        id_inspection: id,
        id_inspection_component: component.inspectionComponent.id,
        id_process: idProcessInspection,
        cost: data?.cost,
        comment: undefined,
        inspection_qualifications: {
          data: {
            id_employee: idEmployee,
            id_inspection_assessment: !component?.inspectionParameter?.assessment?.id ? undefined : data.answer,
            id_inspection_parameter: component?.inspectionParameter?.id,
            value: !component?.inspectionParameter?.assessment?.id ? data.answer : undefined,
          },
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }
    if (component.slug === 'mileage') {
      await this.dealAutoAttribute(component, mileageAttribute, data.answer)
    }
    await this.addFiles(data.photo, fileProperties, idProcess, resp)
    this.$emit('update:inspection')
  }

  async editComponentPiece (component, data, field, order = 1) {
    if (!data?.[field]?.answer) return

    if (data?.[field]?.answer && component.findInspectionParameterByOrder(order)?.assessment.id !== data?.[field]?.answer) {
      const fields = {
        id: component.findInspectionParameterByOrder(order).id,
        id_inspection_assessment: data?.[field]?.answer,
        validated: this.supervisor,
      }
      await this.pushData({ model: 'InspectionQualification', fields })
    }
  }

  async editMultipleComponent (component, statusData, idEmployee, order) {
    if (component.findInspectionQualificationByOrder(order)) {
      const ids = component.findInspectionQualificationByOrder(order).map(_ => ({
        parameter: _.id, assessment: _.assessment.id,
      }))

      const deleteIds = ids.filter(_ => !statusData?.includes(_.assessment))
      const addIds = statusData?.filter(_ => !ids.some(id => id.assessment === _))

      if (addIds.length) {
        await Promise.all((addIds as Record<string, any>).map(async _ => {
          const fields = {
            id_inspection_inspected_component: component.id,
            id_inspection_parameter: component.findInspectionQualificationByOrder(order)?.[0]?.parameter?.id,
            id_inspection_assessment: _,
            id_employee: idEmployee,
            validated: this.supervisor,
          }
          await this.pushData({ model: 'InspectionQualification', fields })
        }))
      }

      if (deleteIds.length) {
        await Promise.all((deleteIds as Record<string, any>).map(async _ => {
          await this.deleteInspectionQualification(_.parameter)
        }))
      }
    }
  }

  async editComponentPieceAttach (component, data, field, statusData, idEmployee, firstOrder = 1, secondOrder = 2) {
    if (component.findInspectionParameterByOrder(firstOrder).assessment.id !== data?.[field]?.answer) {
      const fields = {
        id: component.findInspectionParameterByOrder(firstOrder).id,
        id_inspection_assessment: data?.[field]?.answer,
        validated: this.supervisor,
      }
      await this.pushData({ model: 'InspectionQualification', fields })
    }

    if (component.findInspectionQualificationByOrder(secondOrder)) {
      const ids = component.findInspectionQualificationByOrder(secondOrder).map(_ => ({
        parameter: _.id, assessment: _.assessment.id,
      }))

      const deleteIds = ids.filter(_ => !statusData.includes(_.assessment))
      const addIds = statusData.filter(_ => !ids.some(id => id.assessment === _))

      if (addIds.length) {
        await Promise.all((addIds as Record<string, any>).map(async _ => {
          const fields = {
            id_inspection_inspected_component: component.id,
            id_inspection_parameter: component.findInspectionQualificationByOrder(secondOrder)?.[0]?.parameter?.id,
            id_inspection_assessment: _,
            id_employee: idEmployee,
            validated: this.supervisor,
          }
          await this.pushData({ model: 'InspectionQualification', fields })
        }))
      }

      if (deleteIds.length) {
        await Promise.all((deleteIds as Record<string, any>).map(async _ => {
          await this.deleteInspectionQualification(_.parameter)
        }))
      }
    }
  }

  async deleteInspectionQualification (parameter) {
    await this.removeData({
      model: 'InspectionQualification',
      fields: { id: parameter },
    })
  }

  async deleteInspectionInspectedComponent (component) {
    await this.removeData({
      model: 'InspectionInspectedComponent',
      fields: { id: component },
    })
  }

  async sendComponentPieceAttach (component, data, fileProperties, id) {
    const { idProcess, idEmployee, inspection, idProcessInspection } = this
    let resp

    const statusData = data?.status?.provisional ? [data.status.provisional] : data.status.answer
    const paintStatusData = data?.paintStatus?.provisional ? [data.paintStatus.provisional] : data?.paintStatus?.answer
    const inspectionUpdated = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: inspection.id } },
      force: true,
    })

    const matchingInspectedComponent = inspectionUpdated.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      resp = matchingInspectedComponent
      await this.editComponentPieceAttach(resp, data, 'original', statusData, idEmployee)
      if (paintStatusData?.length) {
        await this.editComponentPieceAttach(resp, data, 'paintOriginal', paintStatusData, idEmployee, 3, 4)
      }

      await this.pushData({
        model: 'InspectionInspectedComponent',
        fields: { id: resp.id, cost: data?.cost },
      })
    } else {
      const paintStatus = paintStatusData ? paintStatusData?.map(status => {
        return {
          id_employee: idEmployee,
          id_inspection_parameter: component?.findQuestion(4)?.id,
          id_inspection_assessment: status,
        }
      }) : []

      const fields = {
        id_inspection: id,
        id_inspection_component: component.inspectionComponent.id,
        id_process: idProcessInspection,
        cost: data?.cost,
        comment: undefined,
        inspection_qualifications: {
          data: [
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(1)?.id,
              id_inspection_assessment: data?.original?.answer,
            },
            ...statusData?.map(status => {
              return {
                id_employee: idEmployee,
                id_inspection_parameter: component?.findQuestion(2)?.id,
                id_inspection_assessment: status,
              }
            }),
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(3)?.id,
              id_inspection_assessment: data?.paintOriginal?.answer,
            },
            ...paintStatus,
          ].filter(_ => _?.id_inspection_assessment),
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }

    await this.addFiles(data.photo, fileProperties, idProcess, resp)
    this.$emit('update:inspection')
  }

  async sendComponentSpare (component, data, fileProperties, id, newInspection = null) {
    const { idProcess, idEmployee, inspection, idProcessInspection } = this
    let resp

    const shouldData = data?.should?.answer
    const presentData = data?.present?.answer
    const originalData = data?.original?.answer
    let statusData = data?.status?.provisional ? [data.status.provisional] : data.status.answer
    statusData = statusData || []
    const inspectionUpdated = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: inspection.id } },
      force: true,
    })

    const matchingInspectedComponent = !newInspection ? inspectionUpdated.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    ) : newInspection.inspectedComponents.find(ic => ic.inspectionComponent.id === component?.inspectionComponent?.id)

    if (matchingInspectedComponent) {
      resp = matchingInspectedComponent
      await this.editComponentPiece(resp, data, 'should')
      await this.editComponentPiece(resp, data, 'present', 2)
      await this.editComponentPiece(resp, data, 'original', 3)
      await this.editMultipleComponent(resp, statusData, idEmployee, 4)

      await this.pushData({
        model: 'InspectionInspectedComponent',
        fields: { id: resp.id, cost: data?.cost },
      })
    } else {
      const fields = {
        id_inspection: id,
        id_inspection_component: component.inspectionComponent.id,
        id_process: idProcessInspection,
        cost: data?.cost,
        comment: undefined,
        inspection_qualifications: {
          data: [
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(1)?.id,
              id_inspection_assessment: shouldData,
            },
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(2)?.id,
              id_inspection_assessment: presentData,
            },
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(3)?.id,
              id_inspection_assessment: originalData,
            },
            ...statusData?.map(st => {
              return {
                id_inspection_assessment: st,
                id_employee: idEmployee,
                id_inspection_parameter: component?.findQuestion(4)?.id,
              }
            }),
          ].filter(_ => _?.id_inspection_assessment),
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }

    await this.addFiles(data.photo, fileProperties, idProcess, resp)
    this.$emit('update:inspection')
  }

  async sendComponentPiece (component, data, fileProperties, id) {
    const { idProcess, idEmployee, inspection, idProcessInspection } = this
    let resp

    const statusData = data?.status?.provisional ? [data.status.provisional] : data.status.answer
    const inspectionUpdated = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: inspection.id } },
      force: true,
    })

    const matchingInspectedComponent = inspectionUpdated.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      resp = matchingInspectedComponent

      await this.editComponentPiece(resp, data, 'original', 1)
      await this.editComponentPiece(resp, data, 'paintOriginal', 2)
      await this.editMultipleComponent(resp, statusData, idEmployee, 3)

      await this.pushData({
        model: 'InspectionInspectedComponent',
        fields: { id: resp.id, cost: data?.cost },
      })
    } else {
      const fields = {
        id_inspection: id,
        id_inspection_component: component.inspectionComponent.id,
        id_process: idProcessInspection,
        cost: data?.cost,
        comment: undefined,
        inspection_qualifications: {
          data: [
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(1)?.id,
              id_inspection_assessment: data?.paintOriginal?.answer,
            },
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(2)?.id,
              id_inspection_assessment: data?.original?.answer,
            },
            ...statusData.map(status => {
              return {
                id_employee: idEmployee,
                id_inspection_parameter: component?.findQuestion(3)?.id,
                id_inspection_assessment: status,
              }
            }),
          ].filter(_ => _.id_inspection_assessment),
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }

    await this.addFiles(data.photo, fileProperties, idProcess, resp)
    this.$emit('update:inspection')
  }

  async sendComponentWheel (component, data, fileProperties, id, newInspection = null) {
    const { idProcess, idEmployee, inspection, idProcessInspection } = this
    let resp

    let statusData = data?.status?.provisional ? [data.status.provisional] : data.status.answer
    statusData = statusData || []
    const answer = sortArray(component.inspectionComponent.getComponentOptionByOrder(6), 'name', true, '%')[data.level.answer]
    const inspectionUpdated = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: inspection.id } },
      force: true,
    })

    const matchingInspectedComponent = !newInspection ? inspectionUpdated.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    ) : newInspection.inspectedComponents.find(ic => ic.inspectionComponent.id === component?.inspectionComponent?.id)

    if (matchingInspectedComponent) {
      resp = matchingInspectedComponent

      await this.editQualificationValue(resp.inspectionQualifications.find(qualification => qualification.parameter.order === 1), data.detail, 'brand')
      await this.editQualificationValue(resp.inspectionQualifications.find(qualification => qualification.parameter.order === 2), data.detail, 'height')
      await this.editQualificationValue(resp.inspectionQualifications.find(qualification => qualification.parameter.order === 3), data.detail, 'width')
      await this.editQualificationValue(resp.inspectionQualifications.find(qualification => qualification.parameter.order === 4), data.detail, 'diameter')
      await this.editComponentPiece(resp, data, 'original', 5)
      await this.editComponentPiece(resp, {
        level: { answer: answer?.id },
      }, 'level', 6)
      await this.editMultipleComponent(resp, statusData, idEmployee, 7)
      await this.editComponentPiece(resp, data, 'new', 8)
      await this.pushData({
        model: 'InspectionInspectedComponent',
        fields: { id: resp.id, cost: data?.cost },
      })
    } else {
      const fields = {
        id_inspection: id,
        id_inspection_component: component.inspectionComponent.id,
        id_process: idProcessInspection,
        cost: data?.cost,
        comment: undefined,
        inspection_qualifications: {
          data: [
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(1)?.id,
              value: data?.detail?.brand,
            },
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(2)?.id,
              value: data?.detail?.height,
            },
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(3)?.id,
              value: data?.detail?.width,
            },
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(4)?.id,
              value: data?.detail?.diameter,
            },
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(5)?.id,
              id_inspection_assessment: data?.original?.answer,
            },
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(6)?.id,
              id_inspection_assessment: answer?.id,
            },
            ...statusData?.map(status => {
              return {
                id_employee: idEmployee,
                id_inspection_parameter: component?.findQuestion(7)?.id,
                id_inspection_assessment: status,
              }
            }),
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(8)?.id,
              id_inspection_assessment: data?.new?.answer,
            },
          ].filter(_ => _?.id_inspection_assessment || _?.value),
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }

    await this.addFiles(data.photo, fileProperties, idProcess, resp)
    this.$emit('update:inspection')
  }

  async sendComponentOptics (component, data, fileProperties, id) {
    const { idProcess, idEmployee, inspection, idProcessInspection } = this
    let resp

    const statusData = data?.status?.provisional ? [data.status.provisional] : data.status.answer

    const inspectionUpdated = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: inspection.id } },
      force: true,
    })

    const matchingInspectedComponent = inspectionUpdated.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      resp = matchingInspectedComponent

      await this.editMultipleComponent(resp, statusData, idEmployee, 1)

      await this.pushData({
        model: 'InspectionInspectedComponent',
        fields: { id: resp.id, cost: data?.cost },
      })
    } else {
      const fields = {
        id_inspection: id,
        id_inspection_component: component.inspectionComponent.id,
        id_process: idProcessInspection,
        cost: data?.cost,
        comment: undefined,
        inspection_qualifications: {
          data: [
            ...statusData.map(status => {
              return {
                id_employee: idEmployee,
                id_inspection_parameter: component?.findQuestion(1)?.id,
                id_inspection_assessment: status,
              }
            }),
          ],
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }

    await this.addFiles(data.photo, fileProperties, idProcess, resp)
    this.$emit('update:inspection')
  }

  async pushInspectionQualification (idEmployee, matchingParam, data, paramKey, matchingInspectedComponentId) {
    const fields = {
      id: matchingParam?.id,
      id_employee: idEmployee,
      id_inspection_inspected_component: matchingInspectedComponentId,
      id_inspection_parameter: data[paramKey]?.parameter?.id,
      value: data[paramKey]?.maintenance?.isMileage.toString() || '0',
      validated: this.supervisor,
    }
    await this.pushData({ model: 'InspectionQualification', fields })
  }

  async updateOrInsertParam (idEmployee, matchingInspectedComponent, data, paramKey) {
    const matchingParam = matchingInspectedComponent.findInspectionParameter(data[paramKey]?.parameter?.id)

    await this.pushInspectionQualification(idEmployee, matchingParam, data, paramKey, matchingInspectedComponent.id)
  }

  async sendMaintenance (component, data, id) {
    const { idEmployee, inspection, idProcessInspection } = this

    const inspectionUpdated = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: inspection.id } },
      force: true,
    })

    const matchingInspectedComponent = inspectionUpdated.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      await this.updateOrInsertParam(idEmployee, matchingInspectedComponent, data, 'maxMaintenanceDone')
      await this.updateOrInsertParam(idEmployee, matchingInspectedComponent, data, 'maxMaintenancePrepaid')

      if (data?.cost) {
        await this.pushData({
          model: 'InspectionInspectedComponent',
          fields: { id: matchingInspectedComponent.id, cost: data?.cost },
        })
      }
    } else {
      const fields = {
        id_inspection: id,
        id_inspection_component: component.inspectionComponent.id,
        id_process: idProcessInspection,
        cost: data?.cost,
        comment: undefined,
        inspection_qualifications: {
          data: [{
            id_employee: idEmployee,
            id_inspection_parameter: data.maxMaintenanceDone?.parameter?.id,
            value: data.maxMaintenanceDone?.maintenance?.isMileage.toString() || '0',
          },
          {
            id_employee: idEmployee,
            id_inspection_parameter: data.maxMaintenancePrepaid?.parameter?.id,
            value: data.maxMaintenancePrepaid?.maintenance?.isMileage.toString() || '0',
          },
          ],
        },
      }
      await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }

    this.$emit('update:inspection')
  }

  async created () {
    this.inspectionStatus.active = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'inspection' } } }, { status: { name: { _eq: 'active' } } }] },
    })
    this.inspectionStatus.close = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'inspection' } } }, { status: { name: { _eq: 'closed' } } }] },
    })
    this.inspectionStatus.finished = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'inspection' } } }, { status: { name: { _eq: 'finished' } } }] },
    })
    this.inspectionStatus.validated = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'inspection' } } }, { status: { name: { _eq: 'validated' } } }] },
    })
    this.inspectionStatus.supervision = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'inspection' } } }, { status: { name: { _eq: 'supervision' } } }] },
    })

    this.inspectionStatus.successful = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'inspection' } } }, { status: { name: { _eq: 'successful' } } }] },
    })

    const filter = {
      _and: [
        { type: { name: { _eq: 'not_qualify' } } },
        { status: { process: { table_name: { _eq: 'inspection' } } } },
      ],
    }

    this.inspectionClosingReason.notQualify = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter,
    })
    const filter2 = {
      _and: [
        { type: { name: { _eq: 'successful' } } },
        { status: { process: { table_name: { _eq: 'inspection' } } } },
      ],
    }

    this.inspectionClosingReason.closed = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: filter2,
    })

    this.autoStatus.deprecated = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'auto' } } }, { status: { name: { _eq: 'deprecated' } } }] },
    })

    this.dealStatus.lost = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'deal' } } }, { status: { name: { _eq: 'lost' } } }] },
    })

    this.dealClosingReason.not_qualify = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: { _and: [{ type: { name: { _eq: 'not_qualify' } } }, { status: { process: { table_name: { _eq: 'deal' } } } }] },
    })
  }

  async closeInspection (id, idProcessStatus, idClosingReason) {
    await this.pushData({
      model: 'Inspection',
      fields: {
        id,
        id_process_status: idProcessStatus,
        id_closing_reason: idClosingReason,
      },
    })
    this.$emit('update:inspection')
  }

  async putInspectorQualification (rating) {
    const { inspectionStatus, inspectionClosingReason, inspection } = this

    if (inspection?.metadata?.validated && !inspection?.supervisorQualification) {
      const status = inspection?.supervisorQualification ? inspectionStatus?.successful?.[0]?.id : inspectionStatus.finished?.[0]?.id
      await this.pushData({
        model: 'Inspection',
        fields: {
          id: this.inspection.id,
          id_process_status: status,
          inspector_qualification: rating,
        },
      })
    } else if (inspection?.supervisorQualification && this.inspection?.metadata?.inspectorAllValidated) {
      await this.pushData({
        model: 'Inspection',
        fields: {
          id: this.inspection.id,
          id_process_status: inspectionStatus.close?.[0]?.id,
          id_closing_reason: inspectionClosingReason.closed?.[0]?.id,
          inspector_qualification: rating,
        },
      })
    } else if (this.inspection?.metadata?.inspectorAllValidated) {
      await this.pushData({
        model: 'Inspection',
        fields: {
          id: this.inspection.id,
          id_process_status: inspectionStatus.finished?.[0]?.id,
          inspector_qualification: rating,
        },
      })
    }
  }

  async putSupervisorQualification (rating, cost, comment, isOver) {
    const { inspectionStatus, inspectionClosingReason } = this
    if (!this.inspection?.id) return
    const inspection = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: this.inspection.id } },
      force: true,
    })
    if (isOver) {
      await this.pushData({
        model: 'Inspection',
        fields: {
          id: this.inspection.id,
          id_supervisor: this.idEmployee,
          id_process_status: inspectionStatus.close?.[0]?.id,
          id_closing_reason: inspectionClosingReason?.notQualify?.[0]?.id,
          supervisor_qualification: rating,
        },
      })

      await this.updateDealProcessStatusAndClosingReason({
        id: this.inspection.deal.id,
        idProcessStatus: this.dealStatus?.lost?.[0]?.id,
        idClosingReason: this.dealClosingReason?.not_qualify?.[0]?.id,
      })

      await this.pushData({
        model: 'Auto',
        fields: {
          id: this.inspection.auto.id,
          id_process_status: this.autoStatus?.deprecated?.[0]?.id,
        },
      })
    } else {
      const metadata = inspection?.metadata
      metadata.authorizedAmount = cost
      metadata.comment = comment
      if (this.inspection?.inspectorQualification) {
        await this.pushData({
          model: 'Inspection',
          fields: {
            id: this.inspection.id,
            id_supervisor: this.idEmployee,
            id_process_status: inspectionStatus.close?.[0]?.id,
            id_closing_reason: inspectionClosingReason.closed?.[0]?.id,
            supervisor_qualification: rating,
            metadata,
          },
        })
      } else {
        await this.pushData({
          model: 'Inspection',
          fields: {
            id: this.inspection.id,
            id_supervisor: this.idEmployee,
            supervisor_qualification: rating,
            metadata,
          },
        })
      }

      const process = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ process: { table_name: { _eq: 'negotiation' } } }, { status: { name: { _eq: 'pending' } } }] },
      })

      const inspectionUpdated = await this.fetchData({
        query: { name: 'fetch', model: 'Inspection', params: { id: this.inspection.id } },
        force: true,
      })

      const processPurchase = (await this.fetchData({
        query: { name: 'find', model: 'Process' },
        filter: {
          table_name: { _eq: 'purchase_order' },
        },
      }))[0]

      const displayConsignment = processPurchase?.config?.displayConsignment
      const responseConsignment = displayConsignment ? {
        id_employee: this.idEmployee,
        type: NegotiationResponseType.offerConsignment,
        amount: inspectionUpdated.amountNegotiationConsignment,
      } : {
        id_employee: this.idEmployee,
        type: NegotiationResponseType.offerConsignment,
        amount: null,
      }
      await this.pushData({
        model: 'Negotiation',
        fields: {
          id_inspection: this.inspection.id,
          id_process_status: process?.[0]?.id,
          negotiation_responses: {
            data: [{
              id_employee: this.idEmployee,
              type: NegotiationResponseType.offer,
              amount: inspectionUpdated.amountNegotiation,
            }, responseConsignment].filter(_ => _.amount),
          },
        },
      })
    }
  }

  async dealAutoAttribute (component, attribute, value) {
    const { inspection, idProcessInspection } = this

    let fields
    if (attribute?.id) {
      fields = {
        id: attribute.id,
        value,
      }
    } else {
      fields = {
        id_process_record: inspection.id,
        value,
        id_process: idProcessInspection,
        id_deal: inspection?.deal?.id,
        id_component: component.id,
      }
    }

    await this.pushData({ model: 'DealAutoAttribute', fields })
  }

  async getDealAutoAttribute (idComponent, idProcess, idProcessRecord) {
    return this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: {
        _and: [
          { id_component: { _eq: idComponent } },
          { id_process: { _eq: idProcess } },
          { id_process_record: { _eq: idProcessRecord } },
        ],
      },
      force: true,
    })
  }

  getQuestionScore (questionIndex, field, data, questionSet) {
    if (!questionIndex || !field || !data || !questionSet) return null

    if (field?.provisional) {
      return null
    }

    if (field?.provisional === false && field.answer === null) {
      return { score: 1 }
    }

    return questionSet
      ?.findQuestion(questionIndex)
      ?.inspectionAssessments
      ?.find(item => {
        if (field?.answer === item.id && item?.score) {
          return true
        }

        return (Array.isArray(field?.answer) && field?.answer?.includes(item.id)) && item.score
      })
  }

  isAnyFieldValid (fields, data, questionSet, index = 1) {
    let hasCost = false
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i]
      const questionIndex = i + index
      const assessment = this.getQuestionScore(questionIndex, data?.[field], data, questionSet)

      if (assessment?.score) {
        hasCost = true
        break
      }
    }
    return hasCost
  }

  getQuestionTotalScore (questionIndex: number, field: any, questionSet: any): number | null {
    if (!questionIndex || !field || !questionSet) return null

    return questionSet
      ?.findQuestion(questionIndex)
      ?.inspectionAssessments
      ?.find(item => {
        if (Array.isArray(field?.answer)) {
          return field?.answer.includes(item.id) && item.score
        } else {
          return field?.answer === item.id && item.score
        }
      })?.score || null
  }

  getTotalScore (fields: any[], data: any, questionSet: any, index = 1): number {
    let totalScore = 0

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i]
      const questionIndex = i + index
      const score = this.getQuestionTotalScore(questionIndex, data?.[field], questionSet)

      if (score) {
        totalScore += score
      }
    }

    return totalScore
  }

  getQuestion (data, questionIndex) {
    return data?.findQuestion(questionIndex)?.name
  }

  getQuestionOptions (data, questionIndex) {
    return data?.findQuestion(questionIndex)?.inspectionAssessments || []
  }

  getQuestionOptionsScoreNotZero (data, questionIndex) {
    return data?.findQuestion(questionIndex)?.inspectionAssessments?.filter(item => item.score !== 0).sort((a, b) => a.id - b.id)
  }

  getQuestionOptionScoreZero (data, questionIndex) {
    return data?.findQuestion(questionIndex)?.inspectionAssessments?.filter(item => item.score === 0)
  }

  getStatusAnswer (data, questionIndex) {
    return this.getQuestionOptions(data, questionIndex)?.find(item => item.score === 0)
  }

  getStatusItem (data, questionIndex) {
    return this.getQuestionOptions(data, questionIndex)?.find(item => item.score !== 0)
  }

  isComponentCategory (category) {
    return category?.name && category?.components
  }

  async createInspection (inspection) {
    await this.pushData({
      model: 'Inspection',
      fields: {
        id: inspection.id,
        id_process_status: this.inspectionStatus.active?.[0]?.id,
        date: dayjs().format('MM/DD/YYYY HH:mm:ss'),
        id_inspector: this.idEmployee,
      },
    })
  }

  async inspectionInSupervision (inspection) {
    await this.pushData({
      model: 'Inspection',
      fields: {
        id: inspection.id,
        id_process_status: this.inspectionStatus.supervision?.[0]?.id,
      },
    })
  }

  async addFiles (files, fileProperties, idProcess, resp, fileParameter = null) {
    if (!files?.length) return

    await this.handleFileType(files, fileProperties, idProcess, resp.id, fileParameter)
  }

  async editQualificationValue (qualification, data, field) {
    if (!data?.[field]) return null

    const fields = {
      id: qualification.id,
      value: data[field],
      validated: this.supervisor,
    }

    await this.pushData({ model: 'InspectionQualification', fields })
  }

  separateValues ({ generation, equipment }, equipmentName = 'Equipamiento comfort') {
    const genFilter = generation.attributes.filter(gen => gen.name === equipmentName)

    const matchingValues = []
    const nonMatchingValues = []

    const filterComponents = equipment.values.filter(equip => equip?.inspectionComponents?.length)

    for (const genAttr of genFilter) {
      const genValueId = genAttr.componentValue.id
      const isMatching = filterComponents.find(comfortValue => comfortValue.id === genValueId)

      if (isMatching) {
        matchingValues.push(isMatching)
      }
    }

    for (const value of filterComponents) {
      const valueId = value.id
      const isMatching = genFilter.some(genAttr => genAttr.componentValue.id === valueId)

      if (!isMatching) {
        nonMatchingValues.push(value)
      }
    }

    return {
      present: matchingValues,
      extra: nonMatchingValues.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())),
    }
  }

  async UpdateCategoryToValidated (metadata, inspection) {
    if (!metadata || !inspection?.id) return null

    await this.pushData({
      model: 'Inspection',
      fields: {
        id: inspection.id,
        metadata,
      },
    })
  }

  async updateCategory (categoryName, score, inspection, total = 0, order = null) {
    if (!score || !categoryName) return

    const pos = inspection.metadata?.categories?.findIndex(category => category.name === categoryName)

    if (!inspection?.metadata?.categories) {
      await this.pushData({
        model: 'Inspection',
        fields: {
          id: inspection.id,
          metadata: {
            categories: [
              {
                order,
                name: categoryName,
                score,
                progress: 100,
                inspectorCost: total,
              }],
          },
        },
      })
    } else if (pos === -1) {
      inspection.metadata.categories.push({
        order,
        name: categoryName,
        score,
        progress: 100,
        inspectorCost: total,
      })
      await this.pushData({
        model: 'Inspection',
        fields: { id: inspection.id, metadata: { ...inspection.metadata } },
      })
    } else {
      inspection.metadata.categories[pos].score = score
      inspection.metadata.categories[pos].inspectorCost = total
      await this.pushData({
        model: 'Inspection',
        fields: { id: inspection.id, metadata: { ...inspection.metadata } },
      })
    }
    return inspection
  }

  async updateCategoryProgress (inspection, categoryName, progress, order) {
    const pos = inspection.metadata?.categories?.findIndex(category => category.name === categoryName)

    if (!inspection?.metadata?.categories) {
      await this.pushData({
        model: 'Inspection',
        fields: {
          id: inspection.id,
          metadata: {
            categories: [
              {
                order,
                name: categoryName,
                score: null,
                progress,
              }],
            vehicleStatus: inspection?.metadata?.vehicleStatus,
          },
        },
      })
    } else if (pos === -1 || isNaN(pos)) {
      inspection.metadata.categories.push({
        order,
        name: categoryName,
        score: null,
        progress,
      })
      await this.pushData({
        model: 'Inspection',
        fields: { id: inspection.id, metadata: { ...inspection.metadata } },
      })
    } else {
      inspection.metadata.categories[pos].progress = progress
      await this.pushData({
        model: 'Inspection',
        fields: { id: inspection.id, metadata: { ...inspection.metadata } },
      })
    }
  }

  async updateCategoryStatus (inspection, categoryName, cost = 0, supervisor = false, score = null, order = null) {
    const pos = inspection.metadata?.categories?.findIndex(category => category.name === categoryName)

    if (!inspection?.metadata?.categories) {
      await this.pushData({
        model: 'Inspection',
        fields: {
          id: inspection.id,
          metadata: {
            categories: [
              {
                order,
                name: categoryName,
                score,
                progress: 100,
                inspectorCost: cost,
                inspectorValidated: true,
              }],
          },
        },
      })
    } else if (pos === -1 || isNaN(pos)) {
      inspection.metadata.categories.push({
        order,
        name: categoryName,
        score,
        progress: 100,
        inspectorCost: cost,
        inspectorValidated: true,
      })
      await this.pushData({
        model: 'Inspection',
        fields: { id: inspection.id, metadata: { ...inspection.metadata } },
      })
    } else {
      inspection.metadata.categories[pos].progress = 100
      inspection.metadata.categories[pos].score = score

      if (!supervisor) {
        inspection.metadata.categories[pos].inspectorCost = cost
        inspection.metadata.categories[pos].inspectorValidated = true
      } else {
        inspection.metadata.categories[pos].supervisorCost = cost
        inspection.metadata.categories[pos].validated = true
      }

      await this.pushData({
        model: 'Inspection',
        fields: { id: inspection.id, metadata: { ...inspection.metadata } },
      })
    }
  }

  getQuestionOption (component, order = 1, zero = true, match = 'Si') {
    const { getQuestionOptionScoreZero, getQuestionOptionsScoreNotZero } = this

    if (zero) {
      return getQuestionOptionScoreZero(component, order)?.find(item => item.name === match)
    }

    return getQuestionOptionsScoreNotZero(component, order)?.find(item => item.name === match)
  }

  async insertUpdateDealAutoAttribute (id, idDeal, componentValue, componentId) {
    const { idProcessInspection } = this
    const filter = {
      _and: [
        { id_component_value: { _eq: componentValue.id } },
        { id_component: { _eq: componentId } },
        { id_process: { _eq: idProcessInspection } },
        { id_process_record: { _eq: id } },
        { id_deal: { _eq: idDeal } }],
    }
    const dealAutoAttribute = await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter,
      force: true,
    })

    if (dealAutoAttribute?.length) {
      return
    }
    await this.pushData({
      model: 'DealAutoAttribute',
      fields: {
        id_deal: idDeal,
        id_component_value: componentValue.id,
        id_component: componentId,
        id_process: idProcessInspection,
        id_process_record: id,
      },
    })
  }

  async deleteDealAutoAttribute (attributes) {
    await Promise.all(attributes.map(async att => {
      await this.removeData({ model: 'DealAutoAttribute', fields: { id: att.id } })
    }))
  }

  getOptionName (component, id, order = 1) {
    const options = this.getQuestionOptions(component, order)
    if (!isArray(id)) {
      const option = options.find(option => option.id === id)
      return option?.name
    } else {
      return options.filter(option => id.includes(option.id)).map(option => option.name)
    }
  }

  getArrayOptionsName (component, pos, order = null) {
    if (!order) {
      return sortArray(component?.inspectionComponent?.componentOptions, 'name', true, '%')?.[pos]?.name
    }

    return sortArray(component?.inspectionComponent?.getComponentOptionByOrder(order), 'name', true, '%')?.[pos]?.name
  }

  async updateInspectionQualificationValidated (id) {
    await this.pushData({
      model: 'InspectionQualification',
      fields: {
        id,
        validated: true,
      },
    })
  }

  getAnswer (provisional, status) {
    if (provisional === false) {
      return status
    }

    return provisional
  }

  getTotalCost (formData, field = 'cost') {
    let total = 0
    const keys = Object.keys(formData)

    keys.forEach(key => {
      if (formData[key]?.[field]) {
        total += parseInt(formData[key][field])
      }
    })

    return total
  }

  getValue (data, field) {
    return data?.[field]
  }

  findComponentBySlug (inspectionComponents, slug) {
    return inspectionComponents?.find(component => component.slug === slug)
  }
}
