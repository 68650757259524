
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import DashboardSupervisorContent
    from '@/components/forms/inspection/supervisor/content/electric/DashboardSupervisorContent.vue'
  import ScannerSupervisorContent
    from '@/components/forms/inspection/supervisor/content/electric/ScannerSupervisorContent.vue'
  import { isValidNumber } from '@/utils/general'

@Component({
  methods: { isValidNumber },
  components: {
    ScannerSupervisorContent,
    DashboardSupervisorContent,
    RatingCell,
    GFiles,
    GFormSlot,
    BasePanel,
  },
})
  export default class ElectricSupervisorPanel extends BaseStepper {
  declare $refs: {
    form: HTMLFormElement,
    dashboard: DashboardSupervisorContent
    scanner: ScannerSupervisorContent
  };

  componentKeys = ['dashboard', 'scanner']

  traction = null
  total = null

  formData = {
    dashboard: {
      total: 0,
    },
    scanner: {
      total: 0,
    },
  }

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get scanner () {
    return this.findComponentBySlug(this.components, 'scanner')
  }

  get dashboard () {
    return this.findComponentBySlug(this.components, 'vehicle_dashboard')
  }

  @Watch('step', { immediate: true })
  async onStepChange (val) {
    const {
      componentKeys,
      selfPanel,
      displayQualification,
      categoryName,
      category,
      categoryQualificationConfig,
      inspection,
      progress,
    } = this

    if (displayQualification) {
      this.$emit('input', { categoryName, category, categoryQualificationConfig })
    }

    if (!displayQualification) {
      await this.updateCategoryProgress(inspection, categoryName, progress, 9)
    }
    if (val > componentKeys.length && selfPanel) {
      this.$emit('input', { categoryName, category, categoryQualificationConfig })
      this.$emit('inspection-category-done')
    }
  }

  get progress () {
    const { step, componentKeys } = this

    if (step > 1) {
      return ((step - 1) / componentKeys.length) * 100
    }

    return 0
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  @Watch('inspection', { immediate: true, deep: true })
  onInspectionDataChange (val) {
    const { category } = this

    if (!isValidNumber(this.total)) {
      this.total = val?.metadata?.findCategoryByName(category.name).total
    }
  }

  async send () {
    const { categoryName, category } = this

    let total = 0

    for (const key of ['dashboard', 'scanner']) {
      const answer = await this.$refs[key].send()
      if (answer === false) return false
      total += answer
      this.formData[key].total = total
    }
    this.total = total
    this.$emit('input', { categoryName, category, total })
    this.$emit('inspection-category-done')
  }
  }
