
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { ContentBase } from '@/components/forms/inspection/steppers/content/ContentBase'
  import { sortArray } from '@/utils/array/manipulation'
  import { Debounce } from '@/utils/decorators'
  import { Component as CategoryComponent } from '@/entities/vehicle'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'

@Component({
  components: { RowData },
  methods: {
    sortArray,
  },
})
  export default class EngineSupervisorContent extends ContentBase {
  @Prop({ type: Object }) engine: CategoryComponent
  oilItems = []
  fieldsKeys = ['status', 'functionality', 'oilLevel', 'oil']

  formData = {
    status: {
      answer: null,
      provisional: null,
    },
    functionality: {
      answer: null,
      provisional: null,
    },
    oilLevel: {
      answer: null,
    },
    oil: {
      answer: null,
      provisional: null,
    },
    oilFilter: {
      answer: null,
      provisional: null,
    },
    cost: undefined,
    supervisorCost: undefined,
    photo: null,
  }

  errorIdentifierMessage = ''

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  getName (items, field) {
    return items?.[field]?.name
  }

  async send () {
    const { formData, engine, inspection } = this

    if (formData.cost && (formData.supervisorCost === null || formData.supervisorCost === undefined)) {
      this.errorIdentifierMessage = 'El costo es requerido'
      return false
    } else {
      this.errorIdentifierMessage = ''
    }

    const matchingInspectedComponent = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === engine?.inspectionComponent?.id
    )
    if (matchingInspectedComponent) {
      await this.updateInspectionQualificationValidated(matchingInspectedComponent.findInspectionQualificationByOrder(1)?.[0]?.id)

      if (formData?.supervisorCost) {
        await this.pushData({
          model: 'InspectionInspectedComponent',
          fields: { id: matchingInspectedComponent.id, supervisor_cost: formData.supervisorCost || 0 },
        })
      }
    }

    return parseInt(formData.supervisorCost) || 0
  }

  @Watch('engine', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    const { idProcess, inspection } = this

    const matchingInspectedComponent = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === val?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      this.formData.photo = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: matchingInspectedComponent.id } },
            { parameter: { process: { id: { _eq: idProcess } } } },
            { parameter: { file_type: { name: { _eq: 'photo' } } } },
          ],
        },
        force: true,
      })

      this.processFormData('status', 1, matchingInspectedComponent)
      this.processFormData('functionality', 2, matchingInspectedComponent)
      this.processFormData('oil', 4, matchingInspectedComponent)
      this.processFormData('oilFilter', 5, matchingInspectedComponent)

      this.formData.oilLevel.answer = this.oilItems?.findIndex(item => item.id === matchingInspectedComponent.findInspectionQualificationByOrder(3)?.[0]?.assessment?.id)
      this.formData.cost = matchingInspectedComponent.cost
      this.formData.supervisorCost = matchingInspectedComponent.supervisorCost || matchingInspectedComponent.cost
      this.$emit('input', { total: this.formData.supervisorCost })
      if (matchingInspectedComponent.cost) {
        this.cost = true
      }
    }
  }

  processFormData (field, order, component) {
    const param = component.findInspectionParameterByOrder(order)
    const qual = component.findInspectionQualificationByOrder(order)

    if (!param?.assessment?.score) {
      this.formData[field].provisional = param?.assessment?.id
      this.formData[field].answer = null
    } else {
      this.formData[field].provisional = false
      this.formData[field].answer = qual.map(item => item.assessment.id)
    }
  }

  @Watch('engine', { immediate: true })
  onCategoryComponentChange (val) {
    if (!val) return

    this.oilItems = sortArray(val?.findQuestion(3).inspectionAssessments, 'name', true, '%') || []
  }
  }
