// @ts-nocheck
// Styles
import './GSelect.sass'
// Types
import mixins from '../../../util/mixins'
import VSelect from '../../../components/VSelect/VSelect'

const baseMixins = mixins<InstanceType<typeof VSelect>>(VSelect)

/* @vue/component */
export default baseMixins.extend({
  name: 'g-select',

  computed: {
    inputFilled (): boolean {
      const { lazyValue, initialValue, value } = this

      return lazyValue?.length || initialValue || value
    },
    classes (): object {
      const { inputFilled, isDisabled } = this

      return {
        'g-select': true,
        'g-select--disabled': isDisabled,
        'g-select--has-content': !isDisabled ? inputFilled : false,
        ...VSelect.options.computed.classes.call(this),
      }
    },
  },
})
