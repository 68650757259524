
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import FileView from '@/components/core/files/FileView.vue'
  import { FileProcess } from '@/entities/files'

@Component({
  components: { FileView },
})
  export default class RowData extends Vue {
  @Prop({ type: [String], default: null }) title!: string | null
  @Prop({ type: [String], default: null }) comment: string | null
  @Prop({ type: [String], default: null }) option!: string | null
  @Prop({ type: [Array], default: () => [] }) file!: FileProcess[]
  @Prop({ type: [String, Number, Array], default: null }) data!: string | number | null | Array<string | any>

  get dataIsArray () {
    const { data } = this

    return Array.isArray(data)
  }

  get dataIsStringLong () {
    const { data } = this

    return typeof data === 'string' && data.length > 15
  }

  get isComment () {
    const { comment } = this

    return comment?.length
  }

  get isIconAnswer () {
    const { data } = this

    return data === 'Si' || data === 'No'
  }

  get icon () {
    const { data } = this

    return data === 'Si' ? 'mdi-check-circle' : 'mdi-close-circle'
  }

  get color () {
    const { data } = this

    return data === 'Si' ? 'green' : 'error'
  }
  }
