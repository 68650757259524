
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import { Debounce } from '@/utils/decorators'
  import KeyContent from '@/components/forms/inspection/steppers/content/equipment/basic/KeyContent.vue'
  import CarExhaustContent from '@/components/forms/inspection/steppers/content/CarExhaustContent.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import KeySupervisorContent from '@/components/forms/inspection/supervisor/content/equipment/KeySupervisorContent.vue'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'

@Component({
  components: {
    RowData,
    KeySupervisorContent,
    GRadioButton,
    CarExhaustContent,
    KeyContent,
    RatingCell,
    GFiles,
    GFormSlot,
    BasePanel,
  },
})
  export default class EquipmentSupervisorPanel extends BaseStepper {
  declare $refs: {
    form: HTMLFormElement,
    key: KeySupervisorContent
  };

  componentKeys = ['steeringWheel', 'radio', 'airConditioning', 'centralLock', 'glassLifters', 'electricMirror']

  fieldsKeys = ['status']
  validation = false
  cost = {
    steeringWheel: false,
    radio: false,
    airConditioning: false,
    centralLock: false,
    glassLifters: false,
    electricMirror: false,
  }

  keys = {
    steeringWheel: 'Volante',
    radio: 'Radio',
    airConditioning: 'Aire acondicionado',
    centralLock: 'Cierre centralizado',
    glassLifters: 'Alzavidrios eléctricos total',
    electricMirror: 'Espejos laterales eléctricos',
  }

  key = {
    total: 0,
  }

  total = null

  formData = {
    steeringWheel: {
      status: {
        answer: null,
        provisional: false,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    radio: {
      status: {
        answer: null,
        provisional: false,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    airConditioning: {
      status: {
        answer: null,
        provisional: false,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    centralLock: {
      status: {
        answer: null,
        provisional: false,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    glassLifters: {
      status: {
        answer: null,
        provisional: false,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    electricMirror: {
      status: {
        answer: null,
        provisional: false,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
  }

  errorIdentifierMessage = {
    steeringWheel: '',
    radio: '',
    airConditioning: '',
    centralLock: '',
    glassLifters: '',
    electricMirror: '',
  }

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get basicEquipment () {
    return this.findComponentBySlug(this.components, 'basic_equipment')
  }

  get keyComponent () {
    return this.basicEquipment?.values.find(val => val.value === 'Llaves')
  }

  get allComponents () {
    return this.basicEquipment?.values.filter(val => val.value !== 'Llaves').sort((a, b) => a.inspectionComponents[0].order - b.inspectionComponents[0].order)
  }

  async send () {
    const keyAnswer = await this.$refs.key.send()
    if (keyAnswer === false) return
    const { allComponents, inspection, categoryName, category, formData, componentKeys: keys } = this

    let error = false

    for (const key of keys) {
      if (formData?.[key]?.cost && (formData[key].supervisorCost === null || formData[key].supervisorCost === undefined)) {
        this.errorIdentifierMessage[key] = 'El costo es requerido'
        error = true
      } else {
        this.errorIdentifierMessage[key] = ''
      }
    }

    if (error) return
    await Promise.all(keys.map(async key => {
      const component = allComponents.find(component => component.value === this.keys[key])
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )
      if (matchingInspectedComponent) {
        await this.updateInspectionQualificationValidated(matchingInspectedComponent.findInspectionQualificationByOrder(1)?.[0]?.id)

        if (formData?.[key]?.supervisorCost) {
          await this.pushData({
            model: 'InspectionInspectedComponent',
            fields: { id: matchingInspectedComponent.id, supervisor_cost: formData[key].supervisorCost || 0 },
          })
        }
      }
    }))
    this.total = this.getTotalCost(this.formData, 'supervisorCost') + keyAnswer
    this.$emit('input', { categoryName, category, total: this.total })
    this.$emit('inspection-category-done')
  }

  get allData () {
    const { allComponents, selfPanel } = this

    return {
      allComponents, selfPanel,
    }
  }

  @Watch('allData', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    if (!val.selfPanel) return
    const { idProcess, inspection, componentKeys, autoClose, disabled, displayQualification } = this

    const handleComponentChange = async (component, step, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        if (!autoClose && !disabled && !displayQualification) {
          this.step = step
        }

        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        if (!matchingInspectedComponent.findInspectionParameterByOrder(1)?.assessment?.score) {
          this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(1)?.assessment?.id
          this.formData[key].status.answer = null
        } else {
          this.formData[key].status.provisional = false
          this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(1).map(item => item.assessment.id)
        }

        this.formData[key].cost = matchingInspectedComponent.cost
        this.formData[key].supervisorCost = matchingInspectedComponent.supervisorCost || matchingInspectedComponent.cost
        if (matchingInspectedComponent.cost) {
          this.cost[key] = true
        }
      }
    }

    for (const [index, key] of componentKeys.entries()) {
      await handleComponentChange(val.allComponents[index], index + 2, key)
    }
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  @Watch('inspection', { immediate: true, deep: true })
  onInspectionDataChange (val) {
    const { categoryName } = this
    this.total = val?.metadata?.findCategoryByName(categoryName)?.total
  }
  }
