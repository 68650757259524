import { render, staticRenderFns } from "./BasePanel.vue?vue&type=template&id=2f0fcc0e&scoped=true&"
import script from "./BasePanel.vue?vue&type=script&lang=ts&"
export * from "./BasePanel.vue?vue&type=script&lang=ts&"
import style0 from "./BasePanel.vue?vue&type=style&index=0&id=2f0fcc0e&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f0fcc0e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VExpansionPanel})
