
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { fixDate, isEmpty } from '@/utils/general'
  import { RULES } from '@/components/forms'
  import dayjs from 'dayjs'

@Component
  export default class GDatePicker extends Vue {
  fieldRequired = RULES.isRequired
  menu = false;
  date = '';
  activePicker = '';
  dateSelected = '';
  @Prop({ type: String, default: 'YEAR' }) startType!: string; // YEAR, MONTH, DATE
  @Prop({ type: String, default: '' }) label!: string;
  @Prop({ type: String }) name!: string;
  @Prop({ type: String, default: 'DD/MM/YYYY' }) placeholder!: string;
  @Prop({ type: Number, default: 18 }) startYear!: number; // 18 years ago from current year
  @Prop({ type: [String, Number], default: null }) maxMonths!: number | string | null;
  @Prop({ type: [String, Number], default: null }) maxSelectableDays!: number | string | null;
  @Prop({ type: Array, default: () => [] }) disabledDaysOfWeek!: number[]; // 0 Sunday - 6 saturday
  @Prop({ type: Number, default: 100 }) endYear!: number;
  @Prop({ type: String, default: '' }) value!: string;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) futureDatesAllowed!: boolean;
  @Prop({ type: String, default: 'mdi-calendar-month-outline' }) icon!: boolean;

  @Watch('menu') onChangeMenu (value: boolean): void {
    if (value) {
      setTimeout(() => (this.activePicker = this.startType))
    }
  }

  @Watch('value', { immediate: true }) onChangeValue (value: string): void {
    if (!isEmpty(value)) {
      const dateFormatted = value.includes('/') ? value.split('/')[2].length === 4 : false
      if (!dateFormatted) {
        this.dateSelected = this.formatDate(value)
      } else {
        this.dateSelected = value
      }
    }
  }

  @Watch('date') onChangeDate (value: string): void {
    this.dateSelected = this.formatDate(value)
    this.$emit('input', this.dateSelected)
  }

  get getMaxDate () {
    const {
      futureDatesAllowed,
      maxSelectableDays,
      maxMonths,
      startYear,
      disabledDaysOfWeek,
    } = this

    const date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)

    if (disabledDaysOfWeek.length === 7) {
      return null
    }

    let maxDate
    if (maxSelectableDays) {
      maxDate = this.calculateMaxDateFromSelectableDays(disabledDaysOfWeek, Number(maxSelectableDays))
    } else if (maxMonths) {
      maxDate = this.calculateMaxDateFromMonths(Number(maxMonths), date)
    } else {
      maxDate = this.calculateMaxDateFromYears(futureDatesAllowed, startYear, date)
    }

    return maxDate
  }

  calculateMaxDateFromSelectableDays (disabledDaysOfWeek: number[], maxSelectableDays: number): string {
    const initialDate = dayjs()
    let enabledDaysCount = 0
    let i = 0

    while (enabledDaysCount < maxSelectableDays) {
      const potentialDay = initialDate.add(i, 'day')

      if (!disabledDaysOfWeek.includes(potentialDay.day())) {
        enabledDaysCount++
      }

      i++
    }

    let maxDate = initialDate.add(i, 'day')

    while (disabledDaysOfWeek.includes(maxDate.day())) {
      maxDate = maxDate.add(1, 'day')
    }

    return maxDate.format('YYYY-MM-DD')
  }

  calculateMaxDateFromMonths (maxMonths: number, date: Date): string {
    const maxDate = new Date(date)
    maxDate.setMonth(date.getMonth() + Number(maxMonths))
    return maxDate.toISOString().slice(0, 10)
  }

  calculateMaxDateFromYears (futureDatesAllowed: boolean, startYear: number, date: Date): string {
    const maxDate = new Date(date.setFullYear(date.getFullYear() + (futureDatesAllowed ? startYear : -startYear)))
    return maxDate.toISOString().slice(0, 10)
  }

  get getMinDate (): string {
    const { futureDatesAllowed } = this
    const date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)

    if (this.maxSelectableDays) {
      return date.toISOString().slice(0, 10)
    }

    if (futureDatesAllowed) {
      return new Date(date.setFullYear(date.getFullYear()))
        .toISOString()
        .slice(0, 10)
    }

    return new Date(date.setFullYear(date.getFullYear() - this.endYear))
      .toISOString()
      .slice(0, 10)
  }

  formatDate (date: string): string {
    const data = date.split('-')

    return data[2] + '/' + data[1] + '/' + data[0]
  }

  save (): void {
    this.$emit('input', this.date)
    // @ts-ignore
    this.$refs.menu.save(this.date)
  }

  validateDate (date: string) {
    const regEx = /^(\d{2})\/(\d{2})\/(\d{4})$/

    if (date?.length !== 10 && date?.length > 0) {
      this.dateSelected = null
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          // @ts-ignore
          detail: {
            message: 'Fecha invalida',
            type: 'error',
          },
        })
      )
    } else if (date?.length > 0 && !regEx.exec(date)) {
      const fixDate = date.replaceAll('-', '/').replaceAll(' ', '/').replaceAll(',', '/').replaceAll('.', '/')
      if (regEx.exec(fixDate)) {
        this.dateSelected = this.dateAllowed(fixDate)
      } else {
        this.dateSelected = null
      }
    } else {
      this.dateSelected = this.dateAllowed(date)
    }
  }

  dateAllowed (date: string) {
    if (!date) return null

    if (this.isInvalidateStructureDate(date)) {
      return null
    }

    const newDate = dayjs(fixDate(date))
    const now = dayjs().startOf('day')

    const { futureDatesAllowed } = this

    if (futureDatesAllowed) {
      return now.isAfter(newDate) ? null : date
    }

    return now.isBefore(newDate) ? null : date
  }

  isInvalidateStructureDate (date: string) {
    const splitDate = date.split('/')
    if (isNaN(Number(splitDate[0])) || isNaN(Number(splitDate[1])) || isNaN(Number(splitDate[2]))) {
      return true
    }
    let day = false
    const month = this.isInvalidMonth(Number(splitDate[1]))
    const year = this.isInvalidYear(Number(splitDate[2]))
    if (!month && !year) {
      day = this.isInvalidDay(Number(splitDate[0]), Number(splitDate[1]), Number(splitDate[2]))
    }

    return day || month || year
  }

  isInvalidDay (day: number, month: number, year: number) {
    const date = dayjs(`${year}-${month}-${day}`)

    if (this.maxSelectableDays) {
      const maxSelectableDate = dayjs().add(Number(this.maxSelectableDays), 'day')
      if (date.isAfter(maxSelectableDate) || date.isBefore(dayjs())) {
        return true
      }
    }

    const start = Number(dayjs().set('month', Number(month) - 1).set('year', year).startOf('month').format('DD'))
    const end = Number(dayjs().set('month', Number(month) - 1).set('year', year).endOf('month').format('DD'))

    return day < start || day > end
  }

  isInvalidMonth (month: number) {
    return month < 1 || month > 12
  }

  isInvalidYear (year: number) {
    const { futureDatesAllowed } = this
    if (futureDatesAllowed) {
      return Number(dayjs().format('YYYY')) > year
    }

    return year <= 1900 || year > Number(dayjs().format('YYYY'))
  }

  allowedDates (val) {
    return !this.disabledDaysOfWeek.includes(dayjs(val).day())
  }
  }
