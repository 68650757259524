
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import { Field } from '@/components/forms/fields/field'
  import { Auto, Maintenance, MaintenanceBackupType, MaintenanceType } from '@/entities/public'
  import { ProcessStatus } from '@/entities/settings'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'

@Component({
  components: { RowData, GFiles, GFormSlot, BasePanel },
})
  export default class MaintenancePanelSupervisorExpanded extends Field {
  @Prop({ type: Array, default: () => [] }) maintenanceBackupTypes: MaintenanceBackupType[]
  @Prop({ type: Number }) idProcessMaintenance: number;
  @Prop({ type: Boolean, default: false }) deletePhoto: boolean
  @Prop({ type: Boolean, default: false }) prepaid: boolean
  @Prop({ type: Object }) photoProperties: Record<string, any>;
  @Prop({ type: Object }) auto: Auto;
  @Prop({ type: Boolean, default: false }) summary: boolean
  @Prop({ type: Array }) status: ProcessStatus[];
  @Prop({ type: Array }) existingMaintenance: Maintenance[];
  @Prop() maintenance: MaintenanceType;
  message = ''

  formData = {
    maintenance: {
      photo: [],
      backupType: undefined,
    },
  }

  get isMaintenance () {
    const { maintenance, value, existingMaintenance } = this
    const exist = existingMaintenance?.find(val => val.type.id === maintenance?.id)

    if (exist) {
      return exist
    }

    return value?.find(val => val.type.id === maintenance?.id)
  }

  @Watch('maintenance', { immediate: true, deep: true })
  async onPanelChange () {
    const { isMaintenance, idProcessMaintenance } = this
    if (isMaintenance?.id && isMaintenance?.backupType?.name) {
      this.formData.maintenance = {
        backupType: isMaintenance.backupType.name,
        photo: await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: isMaintenance.id } },
              { parameter: { process: { id: { _eq: idProcessMaintenance } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        }),
      }
    }
  }

  get backupName () {
    const { formData: { maintenance: { backupType } }, maintenanceBackupTypes } = this
    const type = maintenanceBackupTypes?.find(val => val.name === backupType)

    return type?.description
  }
  }
