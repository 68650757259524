
  import { Component, Watch } from 'vue-property-decorator'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import { Debounce } from '@/utils/decorators'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Component as Components } from '@/entities/vehicle'
  import { plainToInstance } from 'class-transformer'
  import { isValidNumber, parseToNumber, replaceText } from '@/utils/general'
  import { DealAutoAttribute, Maintenance } from '@/entities/public'
  import MaintenancePanelExpanded from '@/components/forms/inspection/MaintenancePanelExpanded.vue'
  import _ from 'lodash'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import MaintenancePanelSupervisorExpanded
    from '@/components/forms/inspection/supervisor/components/MaintenancePanelSupervisorExpanded.vue'

@Component({
  methods: { replaceText, isValidNumber },
  components: { MaintenancePanelSupervisorExpanded, RatingCell, MaintenancePanelExpanded, GFiles, GFormSlot, BasePanel },
})
  export default class MaintenanceSupervisorPanel extends BasePanel {
  declare $refs: {
    form: HTMLFormElement,
    prepaid: MaintenancePanelExpanded[],
    done: MaintenancePanelExpanded[]
  };

  components: Components[] = []
  maintenances = []
  maintenanceBackupTypes = []
  prepaidMaintenances = []
  idProcessMaintenance = null
  isMaintenanceMissing = true
  maintenancePhoto = {
    properties: null,
  }

  maintenanceStatus = {
    done: null,
    prepaid: null,
  }

  maxPrepaidMaintenances = 0
  total = null
  componentKeys = ['maintenance']
  mileage: DealAutoAttribute = plainToInstance(DealAutoAttribute, {})

  formData = {
    maintenance: {
      maxMaintenanceDone: {
        maintenance: null,
        parameter: null,
      },
      maxMaintenancePrepaid: {
        maintenance: null,
        parameter: null,
      },
      cost: null,
      supervisorCost: null,
    },
  }

  fields = {
    maintenance: {
      items: [],
    },
    prepaidMaintenance: {
      items: [],
    },
  }

  errorIdentifierMessage = {
    maintenance: '',
  }

  get maintenance () {
    return this.findComponentBySlug(this.components, 'maintenance')
  }

  get questionMaintenanceDone () {
    const { maintenance } = this

    return maintenance?.findQuestion(1)
  }

  get questionMaintenancePrepaid () {
    const { maintenance } = this

    return maintenance?.findQuestion(2)
  }

  get allComponents () {
    const { selfPanel } = this
    return {
      maintenance: this.maintenance,
      maintenancePhoto: this.maintenancePhoto,
      selfPanel,
    }
  }

  get isCost () {
    const { maintenance, inspection } = this
    return {
      maintenance, inspection,
    }
  }

  async send () {
    const { inspection, maintenance: component, categoryName, category, formData } = this

    const matchingInspectedComponent = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      await this.updateInspectionQualificationValidated(matchingInspectedComponent.findInspectionQualificationByOrder(1)?.[0]?.id)
      await this.updateInspectionQualificationValidated(matchingInspectedComponent.findInspectionQualificationByOrder(2)?.[0]?.id)
    }

    await this.pushData({
      model: 'InspectionInspectedComponent',
      fields: {
        id: matchingInspectedComponent.id,
        supervisor_cost: parseInt(formData?.maintenance?.supervisorCost) || 0,
      },
    })
    this.total = formData?.maintenance?.supervisorCost
    this.$emit('input', { categoryName, category, total: this.total })
    this.$emit('inspection-category-done')
  }

  findMaintenanceResponse (maintenances) {
    const isResponse = maintenances?.find(maintenance => maintenance?.backupType?.name && maintenance.backupType.name === 'no_backup')
    if (!isResponse) {
      return null
    }
    return plainToInstance(Maintenance, { type: { mileage: 0 } })
  }

  @Watch('allComponents', { immediate: true, deep: true })
  @Debounce(500)
  async onComponentsChange (val) {
    const { idProcess, inspection, componentKeys, autoClose, disabled, selfPanel, displayQualification } = this

    if (!selfPanel) return

    const handleComponentChange = async (component, step, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents?.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        if (!autoClose && !disabled && !displayQualification) {
          this.step = step
        }

        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        const value = matchingInspectedComponent.componentQualificationValue
        const idAnswer = matchingInspectedComponent.componentQualification?.[0]?.id

        this.formData[key].answer = value?.[0] || idAnswer
      }
    }

    for (const [index, key] of componentKeys.entries()) {
      await handleComponentChange(val[key], index + 1, key)
    }
  }

  async mounted () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'maintenance' } },
    })

    const { category } = this
    this.maxPrepaidMaintenances = process[0].config.maxPrepaidMaintenances

    this.components = category.components

    this.maintenanceStatus.done = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { status: { name: { _eq: 'done' } } },
    })

    this.maintenanceStatus.prepaid = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { status: { name: { _eq: 'prepaid' } } },
    })

    const maintenanceProcess = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'maintenance' } },
    })

    this.idProcessMaintenance = maintenanceProcess?.[0]?.id

    this.maintenanceBackupTypes = await this.fetchData({
      query: { name: 'find', model: 'MaintenanceBackupType' },
      filter: {},
    })

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'maintenance' } } },
    })

    this.maintenancePhoto.properties = {
      accept: fileInfo[0].fileType.mimes,
      multiple: fileInfo[0].multiple,
      fileTypeId: fileInfo[0].fileType.id,
      name: fileInfo[0].name,

    }
  }

  get inspectionData () {
    const { inspection, idProcessInspection, selfPanel } = this

    return {
      inspection,
      idProcessInspection,
      selfPanel,
    }
  }

  @Watch('inspectionData', { immediate: true, deep: true })
  @Debounce(500)
  async onInspectionLoad (val) {
    const { inspection, idProcessInspection } = val

    this.fields.maintenance.items = []
    this.fields.prepaidMaintenance.items = []
    this.maintenances = []
    this.prepaidMaintenances = []
    if (!inspection?.id || !idProcessInspection) return
    const filter = {
      _and: [
        { inspection_component: { component: { slug: { _eq: 'mileage' } } } },
        { id_inspection: { _eq: inspection.id } },
      ],
    }

    const inspectedComponent = await this.fetchData({
      query: { name: 'find', model: 'InspectionInspectedComponent' },
      filter,
      force: true,
    })

    if (inspectedComponent?.length) {
      const dealAutoAttribute = await this.getDealAutoAttribute(inspectedComponent?.inspectionComponent?.component?.[0]?.id, idProcessInspection, inspection.id)
      this.mileage = dealAutoAttribute.find(da => da?.component?.slug === 'mileage')

      const allMaintenances = _.cloneDeep(val.inspection.allMaintenances)
      if (allMaintenances?.length) {
        this.findClosestMileage(this.mileage.value, allMaintenances)
      }
      await this.findMaintenanceType(this.mileage?.value)
    }

    if (inspection?.maintenances?.length) {
      this.maintenances = inspection?.maintenances
    }
    if (inspection?.prepaidMaintenances?.length) {
      this.prepaidMaintenances = inspection?.prepaidMaintenances
      this.fields.maintenance.items = this.fields.maintenance.items.filter(item => !inspection?.prepaidMaintenances?.some(pmt => pmt?.type.id === item.id))
    }
    await this.findPrepaidMaintenanceType(inspection?.maintenances?.[0]?.type?.mileage)
  }

  async findMaintenanceType (km) {
    if (!km) {
      this.fields.maintenance.items = []
      return
    }
    const { maintenances } = this

    const max = maintenances?.length ? maintenances.reduce((prev, current) => {
      return (prev.mileage > current.mileage) ? prev : current
    }) : null

    const search = max?.mileage > Number(km) ? max?.mileage : km

    const items = await this.fetchData({
      query: { name: 'find', model: 'MaintenanceType' },
      filter: { mileage: { _lte: search } },
    })

    this.fields.maintenance.items = items
    await this.findPrepaidMaintenanceType(items?.[items.length - 1]?.mileage)
  }

  async findPrepaidMaintenanceType (mileage, type = 'maintenance') {
    const { maxPrepaidMaintenances } = this
    if (mileage >= maxPrepaidMaintenances) return
    let items
    if (type === 'maintenance') {
      items = await this.fetchData({
        query: { name: 'find', model: 'MaintenanceType' },
        filter: { mileage: { _gt: mileage, _lte: maxPrepaidMaintenances } },
      })
    } else {
      items = await this.fetchData({
        query: { name: 'find', model: 'MaintenanceType' },
        filter: {},
      })
    }

    const isNotBackup = this.prepaidMaintenances?.find(pmt => pmt?.backupType?.name === 'no_backup')

    this.fields.prepaidMaintenance.items = isNotBackup ? items.filter(item => item.id === isNotBackup?.type.id) : items
  }

  get isMaintenancesItems () {
    const { fields: { maintenance: { items } } } = this

    return items?.length
  }

  get isMaintenancesPrepaidItems () {
    const { fields: { prepaidMaintenance: { items } } } = this

    return items?.length
  }

  get auto () {
    const { inspection } = this

    return inspection?.deal?.auto
  }

  findClosestMileage (value, maintenances) {
    const maxMaintenance = maintenances.reverse()
      .find(maintenance =>
        parseToNumber(maintenance?.isMileage) >= parseToNumber(value) &&
        maintenance?.backupType?.name &&
        maintenance.backupType.name !== 'no_backup'
      )

    this.isMaintenanceMissing = !maxMaintenance
  }

  findClosestBackupMileage (maintenances) {
    if (!maintenances?.length) return null

    return maintenances.filter(maintenance => maintenance?.backupType?.name && maintenance.backupType.name !== 'no_backup')?.[0]
  }

  @Watch('isCost', { immediate: true, deep: true })
  onMaintenanceChance (val) {
    const inspected = val.inspection?.inspectedComponents?.find(ic => ic.inspectionComponent.id === val?.maintenance?.inspectionComponent?.id)
    if (inspected) {
      this.formData.maintenance.cost = inspected.cost
      this.formData.maintenance.supervisorCost = inspected.supervisorCost || inspected.cost
    }
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  @Watch('isMaintenanceMissing', { immediate: true })
  onCostMaintenanceChange (val) {
    if (!val && this.formData.maintenance.cost) {
      this.formData.maintenance.cost = 0
    }
  }

  @Watch('inspection', { immediate: true, deep: true })
  onInspectionDataChange (val) {
    const { category } = this

    if (!isValidNumber(this.total)) {
      this.total = val?.metadata?.findCategoryByName(category.name).total
    }
  }
  }
