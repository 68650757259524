
  import { Component, Prop } from 'vue-property-decorator'
  import { Component as Components, Component as inspectionComponents } from '@/entities/vehicle'
  import { InspectionBase } from '@/components/forms/inspection/InspectionBase'

@Component
  export default class BaseStepper extends InspectionBase {
  @Prop({ default: undefined }) inspectionComponents!: inspectionComponents[]
  components: Components[] = []

  step = 1

  back () {
    const { step } = this
    this.step = step - 1
  }

  next () {
    const { step } = this

    this.step = step + 1
  }

  getAutoAttribute (slug) {
    const { inspection } = this
    const attributes = inspection.deal.autoAttributes?.filter(attribute => {
      return attribute.slug === slug && inspection.appraisal.id === attribute.record
    })

    return attributes[0]?.val
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  getValue (data, field) {
    return data?.[field]
  }
  }
