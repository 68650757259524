
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import { Debounce } from '@/utils/decorators'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'
  import { isValidNumber } from '@/utils/general'

@Component({
  methods: { isValidNumber },
  components: { RowData, RatingCell, GFiles, GFormSlot, BasePanel },
})
  export default class OpticSupervisorPanel extends BaseStepper {
  componentKeys = [
    'leftHeadlight', 'rightHeadLight', 'leftFogLamp', 'rightFogLamp', 'leftRearLight', 'rightRearLight',
  ]

  fieldsKeys = ['status']
  validation = false
  total = null

  formData = {
    leftHeadlight: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
    },
    rightHeadLight: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
    },
    leftFogLamp: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
    },
    rightFogLamp: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
    },
    leftRearLight: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
    },
    rightRearLight: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
    },
  }

  errorIdentifierMessage = {
    leftHeadlight: '',
    rightHeadLight: '',
    leftFogLamp: '',
    rightFogLamp: '',
    leftRearLight: '',
    rightRearLight: '',
  }

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get statusItems () {
    const {
      leftHeadlight,
      rightHeadLight,
      leftFogLamp,
      rightFogLamp,
      leftRearLight,
      rightRearLight,
    } = this

    return {
      leftHeadlight: leftHeadlight?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      rightHeadLight: rightHeadLight?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      leftFogLamp: leftFogLamp?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      rightFogLamp: rightFogLamp?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      leftRearLight: leftRearLight?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      rightRearLight: rightRearLight?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
    }
  }

  get statusAnswer () {
    const {
      leftHeadlight,
      rightHeadLight,
      leftFogLamp,
      rightFogLamp,
      leftRearLight,
      rightRearLight,
    } = this

    return {
      leftHeadlight: this.getQuestionOptions(leftHeadlight, 1)?.find(item => item.score === 0),
      rightHeadLight: this.getQuestionOptions(rightHeadLight, 1)?.find(item => item.score === 0),
      leftFogLamp: this.getQuestionOptions(leftFogLamp, 1)?.find(item => item.score === 0),
      rightFogLamp: this.getQuestionOptions(rightFogLamp, 1)?.find(item => item.score === 0),
      leftRearLight: this.getQuestionOptions(leftRearLight, 1)?.find(item => item.score === 0),
      rightRearLight: this.getQuestionOptions(rightRearLight, 1)?.find(item => item.score === 0),
    }
  }

  get rightRearLight () {
    return this.findComponentBySlug(this.components, 'right_rear_light')
  }

  get leftRearLight () {
    return this.findComponentBySlug(this.components, 'left_rear_light')
  }

  get rightFogLamp () {
    return this.findComponentBySlug(this.components, 'right_fog_lamp')
  }

  get leftFogLamp () {
    return this.findComponentBySlug(this.components, 'left_fog_lamp')
  }

  get leftHeadlight () {
    return this.findComponentBySlug(this.components, 'left_head_light')
  }

  get rightHeadLight () {
    return this.findComponentBySlug(this.components, 'right_head_light')
  }

  async send () {
    const { allComponents, inspection, categoryName, category, formData } = this

    let error = false
    const keys = Object.keys(allComponents)

    for (const key of keys) {
      if (formData?.[key]?.cost && (formData[key].supervisorCost === null || formData[key].supervisorCost === undefined)) {
        this.errorIdentifierMessage[key] = 'El costo es requerido'
        error = true
      } else {
        this.errorIdentifierMessage[key] = ''
      }
    }

    if (error) return
    await Promise.all(keys.map(async key => {
      const component = allComponents[key]
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )
      if (matchingInspectedComponent) {
        await this.updateInspectionQualificationValidated(matchingInspectedComponent.findInspectionQualificationByOrder(1)?.[0]?.id)

        if (formData?.[key]?.supervisorCost) {
          await this.pushData({
            model: 'InspectionInspectedComponent',
            fields: { id: matchingInspectedComponent.id, supervisor_cost: formData[key].supervisorCost || 0 },
          })
        }
      }
    }))
    this.total = this.getTotalCost(this.formData, 'supervisorCost')
    this.$emit('input', { categoryName, category, total: this.total })
    this.$emit('inspection-category-done')
  }

  get allComponents () {
    const {
      leftHeadlight,
      rightHeadLight,
      leftFogLamp,
      rightFogLamp,
      leftRearLight,
      rightRearLight,
      selfPanel,
    } = this

    return {
      leftHeadlight,
      rightHeadLight,
      leftFogLamp,
      rightFogLamp,
      leftRearLight,
      rightRearLight,
      selfPanel,
    }
  }

  @Watch('allComponents', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    if (!val.selfPanel) return
    const { idProcess, inspection, componentKeys, autoClose, disabled, displayQualification } = this

    const handleComponentChange = async (component, step, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        if (!autoClose && !disabled && !displayQualification) {
          this.step = step
        }

        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        if (!matchingInspectedComponent.findInspectionParameterByOrder(1)?.assessment?.score) {
          this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(1)?.assessment?.id
          this.formData[key].status.answer = null
        } else {
          this.formData[key].status.provisional = false
          this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(1).map(item => item.assessment.id)
        }

        this.formData[key].cost = matchingInspectedComponent.cost
        this.formData[key].supervisorCost = matchingInspectedComponent.supervisorCost || matchingInspectedComponent.cost
      }
    }

    for (const [index, key] of componentKeys.entries()) {
      await handleComponentChange(val[key], index + 1, key)
    }
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  @Watch('inspection', { immediate: true, deep: true })
  onInspectionDataChange (val) {
    const { category } = this

    if (!isValidNumber(this.total)) {
      this.total = val?.metadata?.findCategoryByName(category.name).total
    }
  }
  }
