
  import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
  export default class GAlert extends Vue {
  @Prop({ type: String, default: 'secondary' }) color!: string;
  @Prop({ type: String, default: 'white' }) textColor!: string;
  @Prop({ type: String, default: '322' }) width!: string;
  @Prop({ type: String, default: '' }) title!: string;
  @Prop({ type: String, default: '' }) subtitle!: string;
  @Prop({ type: Boolean, default: false }) open!: boolean;
  @Prop({ type: Boolean, default: false }) hideCancel!: boolean;
  }
