
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component
  export default class GFormSlot extends Vue {
  @Prop({ type: Boolean, default: false }) disabled: boolean
  @Prop({ type: Boolean, default: false }) supervisor: boolean
  @Prop({ type: Boolean, default: false }) divider: boolean
  @Prop({ type: Boolean, default: false }) showBack!: boolean;
  @Prop({ type: Boolean, default: false }) showCancel!: boolean;
  @Prop({ type: Boolean, default: true }) displayButton!: boolean;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: String, default: 'end' }) justify!: string;

  @Ref('form') readonly form: any;

  validateAndSubmit () {
    if (this.form.validate()) {
      this.$emit('submit')
    }
  }

  validate () {
    return this.form.validate()
  }

  handleSubmit () {
    this.$emit('submit')
  }

  get text () {
    const { supervisor } = this

    return supervisor ? 'Validar' : 'Enviar'
  }
  }
