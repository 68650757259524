import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import { Query } from '@/entities/public/Resource/interfaces'
import { formFilter } from '@/graphql/generated-types'
import { Process } from '@/entities/settings'
import { getObjectValueByPath } from '@/utils/vuetify/helpers'
import { Schedule } from '@/entities/persons'

@Component({
  computed: {
    ...mapGetters('app', ['isMobile', 'process', 'system', 'systemSchedule']),
  },
  methods: {
    ...mapActions('resources/form', ['fetchData', 'getStockDaily', 'getLeadReceivedStock']),
  },
})
export default class Cell extends Vue {
  @Prop({ type: [Object, Array, Number, String] }) readonly item!: any;
  @Prop({ type: Object, required: false }) readonly options!: any;
  @Prop({ type: Boolean, required: false, default: false }) readonly disabled!: boolean;
  isMobile!: boolean

  process!: Process
  system!: string
  systemSchedule!: Array<Schedule>

  getStockDaily!: (payload) => Promise<any>;
  getLeadReceivedStock!: (payload) => Promise<any>;
  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  openLink (link) {
    if (!link?.length) return
    if (!link.startsWith('https://')) {
      link = 'https://' + link
    }

    window.open(link, '_blank')
  }

  isMissingPathProperty (path, item) {
    const val = getObjectValueByPath(item, path)

    return !(val)
  }
}
