
  import { Component, Prop, Ref, Watch } from 'vue-property-decorator'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import { Field } from '@/components/forms/fields/field'
  import { Auto, Maintenance, MaintenanceBackupType, MaintenanceType } from '@/entities/public'
  import { ProcessStatus } from '@/entities/settings'
  import GFiles from '@/components/core/files/GFiles.vue'

@Component({
  components: { GFiles, GFormSlot, BasePanel },
})
  export default class MaintenancePanelExpanded extends Field {
  @Ref('internalForm') readonly internalForm: any;
  @Prop({ type: Array, default: () => [] }) maintenanceBackupTypes: MaintenanceBackupType
  @Prop({ type: Number }) idProcessMaintenance: number;
  @Prop({ type: Boolean, default: false }) deletePhoto: boolean
  @Prop({ type: Boolean, default: false }) prepaid: boolean
  @Prop({ type: Object }) photoProperties: Record<string, any>;
  @Prop({ type: Object }) auto: Auto;
  @Prop({ type: Array }) status: ProcessStatus[];
  @Prop({ type: Array }) existingMaintenance: Maintenance[];
  @Prop() maintenance: MaintenanceType;
  panel = 0
  message = ''
  loading = true

  formData = {
    maintenance: {
      photo: [],
      backupType: undefined,
    },
  }

  iconsAllowed = ['mdi-close-circle-outline', 'mdi-car-search', 'mdi-minus-circle-outline']

  isUploadingFile = false

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  async send () {
    const { maintenance, status, auto, formData, photoProperties, idProcessMaintenance, isMaintenance } = this

    if (!formData.maintenance.backupType) {
      this.message = 'Este campo es requerido'
      return
    } else {
      this.message = ''
    }

    const maintenances = await this.fetchData({
      query: { name: 'find', model: 'Maintenance' },
      filter: {
        id_auto: { _eq: auto.id },
      },
      force: true,
    })

    const exist = maintenances.find(m => m.id === isMaintenance?.id)

    let resp
    if (exist) {
      resp = await this.pushData({
        model: 'Maintenance',
        fields: {
          id: isMaintenance?.id,
          maintenance_backup_type: formData.maintenance.backupType,
          id_process_status: status?.[0]?.id,
        },
      })
    } else {
      resp = await this.insertMaintenance(maintenance, auto, status, formData.maintenance.backupType)
    }
    if (formData.maintenance.photo?.length) {
      await this.handleFileType(formData.maintenance.photo, photoProperties, idProcessMaintenance, resp.id)
    }

    if (formData.maintenance.backupType === 'no_backup' && formData.maintenance.photo?.length) {
      await Promise.all(formData.maintenance.photo.map(async photo => {
        await this.removeFile(photo)
      }))
      this.formData.maintenance.photo = []
    }

    this.$emit('update:inspection')
    this.panel = -1
  }

  async insertMaintenance (maintenance, auto, status, backupType) {
    return this.pushData({
      model: 'Maintenance',
      fields: {
        id_auto: auto.id,
        id_maintenance_type: maintenance.id,
        maintenance_backup_type: backupType,
        id_process_status: status?.[0]?.id,
        mileage: maintenance.mileage,
      },
    })
  }

  get isMaintenance () {
    const { maintenance, value, existingMaintenance } = this
    const exist = existingMaintenance?.find(val => val.type.id === maintenance?.id)

    if (exist) {
      return exist
    }

    return value?.find(val => val.type.id === maintenance?.id)
  }

  get icon () {
    const { isMaintenance, status, formData: { maintenance: { backupType } } } = this

    if (backupType === 'particular' || backupType === 'concessionaire' || backupType === 'no_backup') {
      return 'mdi-check-circle-outline'
    }

    if (isMaintenance?.status?.name === status?.[0].name && backupType === 'no_backup') {
      return 'mdi-car-search'
    }

    if (!isMaintenance && !backupType) {
      return 'mdi-minus-circle-outline'
    }

    return 'mdi-car-search'
  }

  get text () {
    const { icon, iconsAllowed, formData: { maintenance: { backupType } } } = this

    if (backupType === 'no_backup') {
      return 'Sin respaldo'
    }

    if (iconsAllowed[1] === icon) {
      return 'Por validar'
    }

    if (iconsAllowed[2] === icon) {
      return 'Sin reportar'
    }
    return 'Validada'
  }

  get color () {
    const { isMaintenance, status, formData: { maintenance: { backupType } } } = this

    if (backupType === 'particular' || backupType === 'concessionaire' || backupType === 'no_backup') {
      return 'green'
    }

    if (isMaintenance?.status?.name === status?.[0].name && backupType === 'no_backup') {
      return 'orange'
    }

    if (!isMaintenance && !backupType) {
      return 'red'
    }

    return 'orange'
  }

  @Watch('formData.maintenance.backupType', { immediate: true, deep: true })
  onBackupTypeChange () {
    this.message = ''
  }

  get dataMaintenance () {
    const { isMaintenance, idProcessMaintenance, panel } = this

    return {
      isMaintenance,
      idProcessMaintenance,
      panel,
    }
  }

  @Watch('dataMaintenance', { immediate: true, deep: true })
  async onPanelChange (val) {
    this.formData.maintenance.backupType = val?.isMaintenance?.backupType?.name
    if (val?.isMaintenance?.id) {
      this.formData.maintenance.photo = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: val?.isMaintenance.id } },
            { parameter: { process: { id: { _eq: val.idProcessMaintenance } } } },
            { parameter: { file_type: { name: { _eq: 'photo' } } } },
          ],
        },
        force: true,
      })
    }
    if (this.loading) {
      this.panel = -1
      this.loading = false
    }
  }

  validate () {
    const { formData } = this
    if (!formData.maintenance.backupType) {
      this.message = 'Este campo es requerido'
      return
    } else {
      this.message = ''
    }

    // @ts-ignore
    if (this.$refs.internalForm?.validate) {
      // @ts-ignore
      this.$refs.internalForm.validate()
    }
  }

  submit () {
    // @ts-ignore
    if (this.$refs.internalForm?.validateAndSubmit) {
      // @ts-ignore
      this.$refs.internalForm.validateAndSubmit()
    }
  }
  }
